<script setup lang="ts">
import type { Place } from '@/maps'
import type { RequestError } from '@/types'
import { useMapsStore } from '@/store/maps'
import { useLocationStore } from '@/store/location'
import { useProfileStore } from '@/store/profile'
import { useSidebarStore } from '@/store/sidebars'
import { ref, computed, watch, nextTick } from 'vue'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
const { t } = useI18n({ useScope: 'global' })

import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption
} from '@headlessui/vue'
import draggable from 'vuedraggable'

import Sidebar from '@/components/Sidebar/Sidebar.vue'
import SidebarSection from '@/components/Sidebar/SidebarSection.vue'
import TtAccordion from '@/components/TtAccordion.vue'
import TtButton from '@/components/TtButton.vue'
import TtTile from '@/components/TtTile.vue'
import TtInput from '@/components/TtInput.vue'
import TtStarToggle from '@/components/TtStarToggle.vue'
import TtRouteProfileInformation from '@/components/TtRouteProfileInformation.vue'
import { humanReadableTime, humanReadableTimeDecimal } from '@/helpers'
import ArrowRouteGreen from '@/components/Icon/ArrowRouteGreen.vue'
import ArrowRouteRed from '@/components/Icon/ArrowRouteRed.vue'
import Warning from '@/components/Icon/Warning.vue'
import { useNotificationsStore } from '@/store/notifications'

import userPosition from '@/native/geolocation'
import router from '@/router'

const store = useMapsStore()
const locationStore = useLocationStore()
const notificationsStore = useNotificationsStore()
const { closeSidebar } = useSidebarStore()

const { previousRoutes, directions, currentRouteAlternative } =
  storeToRefs(store)

const profileStore = useProfileStore()
const {
  totalDrivingTime
} = storeToRefs(profileStore)

const routeDiffDistance = computed(() => {
  const difference = (directions.value[1].distance - directions.value[0].distance) / 1000;
  return difference
})

const routeDiffTime = computed(() => {
  const difference = (directions.value[1].duration_typical ?? directions.value[1].duration) - (directions.value[0].duration_typical ?? directions.value[0].duration)
  return difference
})

const routeDiffTimeHour = computed(() => {
  const difference = humanReadableTime((directions.value[1].duration_typical ?? directions.value[1].duration) - (directions.value[0].duration_typical ?? directions.value[0].duration)).hours
  return difference
})

const routeDiffTimeMinutes = computed(() => {
  const difference = humanReadableTime((directions.value[1].duration_typical ?? directions.value[1].duration) - (directions.value[0].duration_typical ?? directions.value[0].duration)).minutes
  return difference
})

const route = computed<Place[]>({
  get: () => {
    if (store.route) {
      return store.route
    } else {
      return [
        {
          place_name: t('location.currentLocation'),

          center: [
            userPosition.position.value?.coords.longitude,
            userPosition.position.value?.coords.latitude
          ]
        }
      ] as Place[]
    }
  },
  set: (value) => {
    store.saveRoute(value)
    getDirections(value)
  }
})

const suggestions = ref<Place[]>([])
const query = ref('')

const getDirections = (value?: Place[]) => {
  store.getDirections({ overview: 'full' })
}

const routeInput = ref<{ el: HTMLInputElement } | null>(null)
const routeInputFooter = ref<{ el: HTMLDivElement } | null>(null)
const activeInput = ref(route.value.length ?? 0)
const drivingTimeLeft = ref(totalDrivingTime.value ?? '')
const drivingTimeRest = ref(!!totalDrivingTime.value)

const onBlur = () => {
  setTimeout(() => setActive(route.value.length), 200)
}
const setActive = (index: number) => {
  activeInput.value = index
  // Focus the new input
  nextTick(() => {
    routeInput.value?.el.focus()
  })
}

const removePlace = (event: { oldIndex: number }) => {
  console.log(event)
  route.value = route.value.filter((_, index) => index !== event.oldIndex)
}

const updatePlace = (index: number, place: Place) => {
  if (!place?.center) return
  route.value[index] = place
  route.value = [...route.value] // Force computed to trigger
  setActive(route.value.length)
}

const addPlace = (place: Place) => {
  if (!place?.center) return
  route.value = route.value ? [...route.value, place] : [place]
  setActive(route.value.length)
}

const addRestStop = async () => {
  if (route.value.length < 2) {
    notificationsStore.addNotification(
      'location-select-more-stops',
      t('location.selectStopsForRestingPlaces'),
      'notification',
      true,
      false,
      false,
      false
    )
    return
  }
  /*
  // mapbox category
  const resp = await store.category({
    category: 'gas_station',
    lang: 'sv',
    proximity: route.value[0].center.join(','),
    origin: route.value[route.value.length - 1].center.join(','),
    route: simplified.directions[currentRouteAlternative.value].geometry,
    limit: 10
  })
  */
  /*
  // mapbox suggestion
  const simplified = await store.fetchDirections({ overview: 'simplified' })
  const resp = await store.suggestions({
    search: 'rast',
    lang: 'sv',
    country: 'SE',
    // proximity: route.value[0].center.join(','),
    origin: route.value[route.value.length - 1].center.join(','),
    route: simplified.directions[currentRouteAlternative.value].geometry,
    route_geometry: 'polyline',
    limit: 10,
    time_deviation: 1,
    eta_type: 'navigation',
    poi_category: [
      'rastplats',
      'gas_station'
    ].join(',')
  })
  const distanceLimit = 60000 // 80km x 45m
  const candidates = resp.suggestions.filter((res: any) => {
      if (res.eta) {
      return res.eta > 45
    } else {
      return res.distance > distanceLimit
    }
  }).sort((a: any, b: any) => {
    if (a.eta && b.eta) {
      return a.eta > b.eta
    } else {
      return a.distance > b.distance
    }
  })
  console.log('resp', resp)
  const candidates = resp
  console.log('candidates', candidates)
  if (candidates.length > 0) {
    console.log('candidate', candidates[0], candidates[0].distance)
    const feature = await store.retrieve({ id: candidates[0].mapbox_id, lang: 'sv' })
    console.log('feature', feature)
    if (feature.features.length > 0) {
      const place: Place = {
        place_name: feature.features[0].properties.full_address,
        center: [
          feature.features[0].properties.coordinates.longitude,
          feature.features[0].properties.coordinates.latitude
        ]
      }
      console.log('place', place)
      route.value.splice(1, 0, place)
      route.value = [...route.value]
      console.log('route', route.value)
    }
  }
  */
  //const simplified = await store.fetchDirections({ overview: 'simplified' })
  const resp = await locationStore.locationsByRoute({
    limit: 100,
    radius: 0.9,
    distance: 60,
    pos: {
      lat: route.value[0].center[1],
      lng: route.value[0].center[0]
    },
    //route: simplified.directions[currentRouteAlternative.value].geometry
    route: directions.value[currentRouteAlternative.value].geometry
  })
  if (resp?.length > 0) {
    const place: Place = {
      place_name: `${resp[0].Name}, ${resp[0].Address.city}`,
      center: [
        resp[0].Coordinates.lng,
        resp[0].Coordinates.lat,
      ],
      restStop: true
    }
    //route.value.splice(1, 0, place)
    route.value.push(place)
    route.value = [...route.value]
    setActive(route.value.length)
  } else {
    notificationsStore.addNotification(
      'location-no-resting-found',
      t('location.noRestingPlacesFound'),
      'notification',
      true,
      false,
      false,
      false
    )
  }
}

const loadRoute = (route: Place[]) => {
  store.saveRoute(route)
  getDirections(route)
  setActive(route.length)
}

watch(query, async () => {
  if (query.value.length < 3) {
    suggestions.value = []
  } else {
    const resp = await store.search({
      query: query.value,
      lang: 'sv',
      proximity: 'ip',
      limit: 10
    })
    if ((resp as Place[])?.length) {
      suggestions.value = resp as Place[]
    } else if ((resp as RequestError)?.error) {
      console.error(resp)
    } else {
      suggestions.value = []
    }
  }
})

watch(drivingTimeLeft, (value) => {
  if (!totalDrivingTime.value) {
    return
  }
  const date1 = new Date(0)
  date1.setHours(parseInt(value?.split(':')[0]))
  date1.setMinutes(parseInt(value?.split(':')[1]))
  const date2 = new Date(0)
  date2.setHours(parseInt(totalDrivingTime.value.split(':')[0]))
  date2.setMinutes(parseInt(totalDrivingTime.value.split(':')[1]))
  //drivingTimeRest.value = totalDrivingTime.value && date1.getTime() >= date2.getTime() || false
})

const suggestionOption = ref<Place | null>(null)
watch(suggestionOption, (suggestion: any) => {
  addPlace(suggestion)
  suggestionOption.value = null
})
</script>

<template>
  <div>
    <Sidebar>
      <SidebarSection>
        <TtAccordion>
          <template v-slot:accordionButton>
            <div class="flex flex-1 justify-between">
              <div class="flex items-baseline">
                <h2 class="text-lg font-semibold">{{ t('route.route') }}</h2>
              </div>
              <!-- <TtBattery :percentage="54" /> -->
            </div>
          </template>
          <div class="mt-4">
            <draggable
              v-model="route"
              group="routes"
              item-key="center"
              class="space-y-1.5 text-sm"
              :forceFallback="true"
              fallbackClass="hidden"
              :removeOnSpill="true"
              :onSpill="removePlace"
              direction="vertical"
              handle=".handle"
              tag="ol"
              animation="150"
            >
              <template #item="{ element, index }">
                <li :key="'route' + index">
                  <Combobox
                    v-if="activeInput === index"
                    as="div"
                    :className="`relative`"
                  >
                    <ComboboxInput
                      ref="routeInput"
                      class="form-input flex w-full items-center justify-between rounded border border-tt-pale/25 bg-transparent px-3 py-3 text-base font-light text-white transition-colors placeholder:text-white focus:border-tt-green focus:outline-none focus:ring-0"
                      @change="query = $event.target.value"
                      @keyup.enter="updatePlace(index, suggestions[0])"
                      @blur="onBlur"
                      :placeholder="element.place_name"
                    />
                    <ComboboxOptions
                      class="absolute z-10 mt-3 max-h-72 w-full max-w-full overflow-auto rounded bg-black shadow-sidebar outline-none"
                    >
                      <div
                        v-for="(suggestion, sIndex) in suggestions"
                        :key="sIndex"
                        :class="`${
                          sIndex < suggestions.length - 1
                            ? 'border-b border-tt-gray'
                            : ''
                        }`"
                      >
                        <ComboboxOption
                          as="template"
                          v-slot="{ active }"
                          :value="suggestion"
                        >
                          <button
                            :class="`flex w-full cursor-pointer items-center justify-between rounded px-3 py-3 text-left text-base ${
                              active && 'ring-1 ring-inset ring-tt-green'
                            }`"
                            @click="updatePlace(index, suggestion)"
                          >
                            {{ suggestion.place_name }}
                          </button>
                        </ComboboxOption>
                      </div>
                      <div
                        v-if="suggestions.length > 0"
                        aria-hidden="true"
                        class="sticky -bottom-[1px] z-10 block w-full px-1.5 pb-1.5 pt-8"
                      >
                        <div
                          class="absolute inset-0 z-0 backdrop-blur-[1.5px] [mask-image:linear-gradient(transparent,white_36%)]"
                        ></div>
                        <div
                          class="absolute inset-0 z-0 bg-gradient-to-t from-black from-60% to-[transparent]"
                        ></div>
                      </div>
                    </ComboboxOptions>
                  </Combobox>
                  <TtTile v-else class="active:cursor-grabbing" :active="false">
                    <template #primary>
                      <div class="flex items-center gap-2.5">
                        <button @click="removePlace({ oldIndex: index })">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            fill="none"
                            viewBox="0 0 15 15"
                          >
                            <path
                              fill="#fff"
                              fill-opacity=".44"
                              d="M3.75 1.5A1.5 1.5 0 0 1 5.25 0h4.5a1.5 1.5 0 0 1 1.5 1.5V3h3a.75.75 0 0 1 0 1.5h-.802l-.65 9.107A1.5 1.5 0 0 1 11.302 15H3.698a1.5 1.5 0 0 1-1.496-1.393L1.552 4.5H.75a.75.75 0 0 1 0-1.5h3V1.5ZM5.25 3h4.5V1.5h-4.5V3ZM3.055 4.5l.643 9h7.604l.643-9h-8.89Z"
                            />
                          </svg>
                        </button>
                        <span @click="setActive(index)" class="truncate">{{
                          element.place_name
                        }}</span>
                      </div>
                    </template>
                    <template #secondary>
                      <span class="handle cursor-grab">
                        <svg
                          fill="#FFF"
                          viewBox="0 0 100 80"
                          width="18"
                          height="18"
                        >
                          <rect width="100" height="10"></rect>
                          <rect y="30" width="100" height="10"></rect>
                          <rect y="60" width="100" height="10"></rect>
                        </svg>
                      </span>
                    </template>
                  </TtTile>
                </li>
              </template>
              <template #footer>
                <li key="route-footer">
                  <Combobox as="div" class="relative w-full" v-model="suggestionOption">
                    <ComboboxInput
                      ref="routeInputFooter"
                      class="form-input flex w-full items-center justify-between rounded border border-tt-pale/25 bg-transparent px-3 py-3 text-base font-light text-white placeholder:text-gray-300 focus:border-tt-green focus:outline-none focus:ring-0"
                      @change="query = $event.target.value"
                      @click="setActive(route.length)"
                      @blur="onBlur"
                      @keyup.enter="addPlace(suggestions[0])"
                      autofocus
                      :placeholder="t('location.placeholderStop')"
                    />
                    <ComboboxOptions
                      class="absolute z-10 mt-3 max-h-72 w-full overflow-auto rounded bg-black shadow-sidebar outline-none"
                    >
                      <div
                        v-for="(suggestion, index) in suggestions"
                        :key="index"
                        :class="`${
                          index < suggestions.length - 1
                            ? 'border-b border-tt-gray'
                            : ''
                        }`"
                      >
                        <ComboboxOption
                          as="template"
                          v-slot="{ active }"
                          :value="suggestion"
                        >
                          <button
                            :class="`flex w-full cursor-pointer items-center justify-between rounded px-3 py-3 text-left text-base ${
                              active && 'ring-1 ring-inset ring-tt-green'
                            }`"
                            @click="addPlace(suggestion)"
                          >
                            {{ suggestion.place_name }}
                          </button>
                        </ComboboxOption>
                      </div>
                      <div
                        v-if="suggestions.length > 0"
                        aria-hidden="true"
                        class="sticky -bottom-[1px] z-10 block w-full px-1.5 pb-1.5 pt-8"
                      >
                        <div
                          class="absolute inset-0 z-0 backdrop-blur-[1.5px] [mask-image:linear-gradient(transparent,white_36%)]"
                        ></div>
                        <div
                          class="absolute inset-0 z-0 bg-gradient-to-t from-black from-60% to-[transparent]"
                        ></div>
                      </div>
                    </ComboboxOptions>
                  </Combobox>
                </li>
              </template>
            </draggable>
          </div>
        </TtAccordion>
        <div v-if="drivingTimeRest" class="mt-8 flex flex-col gap-3">
          <TtInput id="drivingTimeLeft" v-model="drivingTimeLeft" type="time" :max="totalDrivingTime as string">{{ t('profile.drivingTimeLeft') }}</TtInput>
        </div>
        <TtButton
          v-if="drivingTimeRest"
          as="button"
          size="small"
          type="secondary"
          class="mt-1 w-full"
          :disabled="!drivingTimeLeft || !!route.find((r: Place) => r.restStop) || directions.length <= 0"
          @click="addRestStop"
        >
          {{ t('profile.AddRestingPlace') }}
        </TtButton>
        <div v-if="directions.length > 0" class="mt-8 flex flex-col gap-3">
          <TtButton
            :as="'router-link'"
            to="/route"
            :size="'large'"
            :type="currentRouteAlternative === 0 ? 'primary' : 'secondary'"
            @click="
              () => {
                //getDirections()
                (currentRouteAlternative = 0)
                closeSidebar()
              }
            "
          >
            <div class="flex w-full justify-between px-4">
              <p>{{ t('route.recommended') }}</p>
              <p class="flex gap-4 font-mono text-base tracking-tighter">
                <span class="flex gap-2">
                  <span
                    v-if="
                      humanReadableTime(
                        directions[0].duration_typical ?? directions[0].duration
                      ).hours > 0
                    "
                  >
                    {{
                      humanReadableTimeDecimal(
                        directions[0].duration_typical ?? directions[0].duration
                      ).timeDecimal.toFixed(2)
                    }}
                    <span
                      :class="`${
                        currentRouteAlternative === 0
                          ? 'text-tt-gray/60'
                          : 'text-tt-green/60'
                      }`"
                    >
                      T
                    </span>
                  </span>
                  <span
                    v-else="
                    humanReadableTime(
                      directions[0].duration_typical ?? direction[0].duration
                    ).hours < 0
                    "
                  >
                    {{
                      humanReadableTime(
                        directions[0].duration_typical ?? directions[0].duration
                      ).minutes
                    }}
                    <span
                      :class="`${
                        currentRouteAlternative === 0
                          ? 'text-tt-gray/60'
                          : 'text-tt-green/60'
                      }`"
                    >
                      M
                    </span>
                  </span>
                </span>
                <span
                  >{{ (directions[0].distance / 1000).toFixed(2) }}
                  <span
                    :class="`${
                      currentRouteAlternative === 0
                        ? 'text-tt-gray/60'
                        : 'text-tt-green/60'
                    }`"
                    >Km</span
                  ></span
                >
              </p>
            </div>
          </TtButton>
          <TtButton
            v-if="directions.length > 1"
            :as="'router-link'"
            to="/route"
            :size="'large'"
            :type="currentRouteAlternative === 1 ? 'primary' : 'secondary'"
            @click="() => {
              (currentRouteAlternative = 1)
              closeSidebar()
            }"
          >
            <div class="flex w-full justify-between px-4">
              <p>{{ t('route.alternative') }}</p>
              <p class="flex gap-4 font-mono text-base tracking-tighter">
                <span class="flex gap-1">
                  <ArrowRouteRed v-if="routeDiffTime > 0" class="self-center"></ArrowRouteRed>
                  <ArrowRouteGreen v-else-if="routeDiffTime < 0" class="self-center"></ArrowRouteGreen>
                  <span
                    v-if="humanReadableTime(
                        directions[1].duration_typical ?? directions[1].duration
                      ).minutes >= 60"
                  >
                    {{ routeDiffTimeHour > 0 ? "+" : "" }}{{ routeDiffTimeHour.toFixed(0) }}
                    <span
                      :class="`${
                        currentRouteAlternative === 1
                          ? 'text-tt-gray/60'
                          : 'text-tt-green/60'
                      }`"
                    >
                      T
                    </span>
                  </span>
                  <span
                  v-else>
                    {{ routeDiffTimeMinutes > 0 ? "+" : "" }}{{ routeDiffTimeMinutes.toFixed(0) }}
                    <span
                      :class="`${
                        currentRouteAlternative === 1
                          ? 'text-tt-gray/60'
                          : 'text-tt-green/60'
                      }`"
                    >
                      M
                    </span>
                  </span>
                </span>
                <span class="flex gap-1">
                  <ArrowRouteRed v-if="routeDiffDistance > 0" class="self-center"></ArrowRouteRed>
                  <ArrowRouteGreen v-else-if="routeDiffDistance < 0" class="self-center"></ArrowRouteGreen>
                  <span>
                    {{ routeDiffDistance > 0 ? "+" : "" }}{{ routeDiffDistance.toFixed(0) }}
                    <span
                      :class="`${
                        currentRouteAlternative === 1
                          ? 'text-tt-gray/60'
                          : 'text-tt-green/60'
                      }`"
                      >Km</span
                    ></span
                  >
                </span>
              </p>
            </div>
          </TtButton>
        </div>
        <TtRouteProfileInformation />
      </SidebarSection>
      <SidebarSection class="border-t border-white/20 py-8">
        <h2 class="text-2xl font-semibold">{{ t('route.previousRoutes') }}</h2>
        <ul class="mt-4 space-y-4">
          <li
            v-for="(route, i) in previousRoutes.slice(0, 3)"
            :key="i"
            class="flex w-full items-center space-x-1 overflow-hidden"
          >
            <TtStarToggle :active="false" />
            <button
              class="text-ellipsis whitespace-nowrap"
              @click="loadRoute(route)"
            >
              {{ route[route.length - 1]?.place_name }}
            </button>
          </li>
        </ul>
        <div class="mt-8">
          <TtButton
            :as="'router-link'"
            to="/route"
            :size="'large'"
            :type="'secondary'"
          >
            {{ t('route.importRoute') }}
          </TtButton>
        </div>
      </SidebarSection>
      <div class="m-5 mt-1 rounded border border-tt-pale/25 text-center items-center justify-center flex flex-col">
        <Warning class="my-4"></Warning>
        <p
        class="mx-6 mb-6"
        >
        {{ t('route.warning') }}
        </p>
      </div>
    </Sidebar>
  </div>
</template>
