<template>
  <svg
    viewBox="0 0 512 437.112092"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group" fill="currentColor" fill-rule="nonzero">
        <path
          d="M244,87.8120918 L232,74.3120918 L227.8,69.6120918 C201.8,40.4120918 162.5,26.8120918 124,33.8120918 C70.7,43.5120918 32,89.9120918 32,144.112092 L32,147.612092 C32,179.912092 45.4,210.712092 69.1,232.712092 L253,404.012092 C253.8,404.712092 254.9,405.212092 256,405.212092 C257.1,405.212092 258.2,404.812092 259,404.012092 L443,232.712092 C466.6,210.712092 480,179.912092 480,147.612092 L480,144.112092 C480,89.9120918 441.3,43.5120918 388,33.8120918 C349.5,26.8120918 310.2,40.4120918 284.2,69.6120918 L280,74.3120918 L268,87.8120918 C265,91.2120918 260.6,93.2120918 256,93.2120918 C251.4,93.2120918 247.1,91.2120918 244,87.8120918 Z M278.9,30.7120918 C311,5.61209179 352.7,-5.08790821 393.7,2.31209179 C462.2,14.8120918 512,74.5120918 512,144.112092 L512,147.612092 C512,183.612092 498.9,218.212092 475.4,245.112092 C472,248.912092 468.5,252.612092 464.7,256.112092 L280.7,427.412092 C279.9,428.212092 279,428.912092 278.1,429.612092 C271.8,434.512092 264,437.112092 256,437.112092 C246.8,437.112092 238,433.612092 231.2,427.412092 L47.2,256.212092 C43.4,252.712092 39.9,249.012092 36.5,245.212092 C13.1,218.212092 0,183.612092 0,147.612092 L0,144.112092 C0,74.5120918 49.8,14.8120918 118.3,2.31209179 C159.2,-5.08790821 200.9,5.51209179 233,30.7120918 C239.7,36.0120918 246,41.8120918 251.7,48.3120918 L255.9,53.0120918 L260.1,48.3120918 C264.3,43.6120918 268.7,39.2120918 273.4,35.2120918 C275.2,33.7120918 277,32.2120918 278.8,30.7120918 L278.9,30.7120918 Z"
        ></path>
      </g>
    </g>
  </svg>
</template>
