import type { Component } from 'vue'
import type {
  Meta,
  Property,
  Range,
  Aggregation,
  Asset,
  MetaValue
} from '@/location'

import _ from 'lodash'
import { storeToRefs } from 'pinia'
import pinia from '@/store/store'
import { useLocationStore } from '@/store/location'
import { useMapsStore } from '@/store/maps'
const { filter } = storeToRefs(useLocationStore(pinia))
const { updateAggregations } = useLocationStore(pinia)
const { map } = storeToRefs(useMapsStore(pinia))

// Backend filter types
// The backend sends us the filters in a different format than we need
// this is so the order is preserved when we display them in the UI
export interface DBFilterOption {
  key: string
  label: string
  filter: DBFilterValue
  component: string
  type: FilterType
  icon: Asset[]
  active: boolean
  props: { [key: string]: any }
  children: DBFilterOption[]
}

export interface DBFilterValue {
  key: string
  field: string
  type: string
  value?: string
  range?: FilterRange
}

// Frontend filter types
export interface FilterSections {
  label: string
  options: Filter
}

export interface Filter {
  [key: string]: FilterOption
}

export interface FilterValue {
  field: string | null | undefined
  key: string | null | undefined
  value: string | boolean | number | FilterRange | null | undefined
}

export interface FilterOption {
  label: string
  key: string
  filter: FilterValue
  component: Component
  type: FilterType // Determines how the filter is applied
  icon?: Asset[]
  active?: boolean | number
  props?: {
    [key: string]: any
  }
  children?: Filter
}

export interface FilterRange {
  min: number
  max: number
}

export interface FilterEvent {
  type: FilterEventType
  value: boolean | FilterRange
}

export enum FilterType {
  Property = 'property',
  Meta = 'meta',
  Range = 'range' // Similar to property, but takes a min and max value
}

export enum FilterEventType {
  Toggle = 'toggle',
  Slider = 'slider'
}

export default class MetaFilter {
  debounceMarkerUpdate = _.debounce(
    () => {
      // Update filter meta and refresh map
      filter.value.properties = this.getProperties()
      filter.value.meta = this.getMeta()
      filter.value.ranges = this.getRanges()
      map.value?.locations?.refresh()
      updateAggregations()
    },
    500,
    { leading: true, trailing: true }
  )
  sections!: FilterSections[]
  sectionMap: { [key: string]: string } = {}
  constructor(sections: FilterSections[]) {
    this.set(sections)
    filter.value.properties = this.getProperties()
    filter.value.meta = this.getMeta()
    filter.value.ranges = this.getRanges()
    updateAggregations().then((val) => {
      if (!val || val?.error) return
      map.value?.locations?.refresh()
    })
  }

  refresh(): void {
    this.debounceMarkerUpdate()
  }

  get(): FilterSections[] {
    return this.sections
  }

  set(sections: FilterSections[]): void {
    this.sections = sections

    const setSectionMap = (option: FilterOption, key: string) => {
      this.sectionMap[key] = key
      // Make sure key and value are lowercase if strings
      if (typeof option.filter.key === 'string')
        option.filter.key = option.filter.key.toLowerCase()
      if (typeof option.filter.value === 'string')
        option.filter.value = option.filter.value.toLowerCase()
      this.sectionMap[`${option.filter.key}|${option.filter.value}`] = key

      // Recursively set section map for children
      if (option.children) {
        for (const childKey in option.children) {
          const child = option.children[childKey]
          setSectionMap(child, childKey)
        }
      }
    }

    // Create key/value pairs for each filter option that can be used to fetch the option
    for (const section of this.sections) {
      for (const key in section.options) {
        const option = section.options[key]
        setSectionMap(option, key)
      }
    }

    this.refresh()
  }

  getKey(key: string): string {
    return this.sectionMap[key] || key.replace('|true', '')
  }

  getOption(key: string): FilterOption | null {
    const findOption = (key: string, options: Filter): FilterOption | null => {
      const option = options[this.getKey(key)]
      if (option) return option
      for (const childKey in options) {
        const child = options[childKey]
        if (child.children) {
          const childOption = findOption(key, child.children)
          if (childOption) return childOption
        }
      }
      return null
    }

    for (const section of this.sections) {
      const option = findOption(key, section.options)
      if (option) return option
    }

    return null
  }

  getParentOption(key: string): FilterOption | null {
    // split key on '.' and remove last element
    const parentKey = key.split('.').slice(0, -1).join('.')
    return this.getOption(parentKey)
  }

  handleEvent(key: string, event?: unknown): void {
    // if we have 'type' in the event, assume it's a FilterEvent
    if (event?.hasOwnProperty('type')) {
      const e = event as FilterEvent
      switch (e.type) {
        case FilterEventType.Toggle:
          this.toggle(key)
          return
        case FilterEventType.Slider:
          const value = e.value as FilterRange | boolean
          if (!value) {
            this.toggle(key)
            break
          }
          const option = this.getOption(key)
          if (option) {
            option.filter.value = value
            option.active = true
          }
          break
        default:
          console.warn('Unhandled event type', event)
          break
      }
    } else {
      // Default: simply toggle the filter
      this.toggle(key)
    }
    const option = this.getOption(key)
    if (option?.active && Object.keys(option.children as object).length) {
      //return
    }
    this.refresh()
  }

  // Toggle the active state of a filter option and refresh the map
  toggle(key: string): void {
    const option = this.getOption(key)
    if (option) {
      option.active = !option.active
      if (option.children) {
        for (const childKey in option.children) {
          const child = option.children[childKey]
          child.active = false
        }
      }
    }
  }

  getActiveOptions(options: Filter, filterType?: FilterType, activateAll: boolean = false): Meta[] {
    const active: Meta[] = []
    for (const key in options) {
      const option = options[key]
      if (option.children) {
        let actives = this.getActiveOptions(option.children, filterType)
        // if is active and no active children, show all children
        if (actives.length === 0 && option.active == true) {
          actives = this.getActiveOptions(option.children, filterType, true)
        }
        active.push(...actives)
      }
      if (filterType && option.type !== filterType) continue
      if ((option.active || activateAll) && option.filter.key && !_.isNil(option.filter.value)) {
        const labelIcon: Asset = { name: option.label, type: 'label', ext: '.label' }
        const value = {
          assets: option.icon || [labelIcon],
          type: option.filter.key as string
        } as Meta
        // Do some special handling for meta filters
        if (option.filter.field && filterType === FilterType.Meta) {
          value.value = {
            [option.filter.field]: option.filter.value
          } as MetaValue
        } else {
          value.value = option.filter.value?.toString() as string
        }

        active.push(value)
      }
    }
    return active
  }

  getActive(filterType?: FilterType): Meta[] {
    const active: Meta[] = []
    for (const section of this.sections) {
      const activeOptions = this.getActiveOptions(section.options, filterType)
      if (activeOptions.length > 0) {
        active.push(...activeOptions)
      }
    }
    return active
  }

  getMeta(): Meta[] {
    return this.getActive(FilterType.Meta)
  }

  getProperties(): Property[] {
    const meta = this.getActive(FilterType.Property)
    return meta.reduce((acc, m) => {
      const prop = acc.find((p) => p.key === m.type) || {
        key: m.type,
        values: []
      }
      prop.values.push(m.value as string)
      acc.push(prop)
      return acc
    }, [] as Property[])
  }

  getRanges(): Range[] {
    const ranges: Range[] = []
    for (const section of this.sections) {
      const options = section.options
      for (const key in options) {
        if (options[key].type === FilterType.Range && options[key].active) {
          const option = options[key]
          const value = option.filter.value as FilterRange
          if (
            !option.filter.key ||
            !option.filter.value ||
            (!value.min && !value.max) && (value.min !== 0 && value.max !== 0)
          ) {
            continue // Skip if no values
          }
          ranges.push({
            key: option.filter.key,
            min: value.min,
            max: value.max
          })
        }
      }
    }
    return ranges
  }

  getIcons(): Map<string, Asset[][]> {
    const sectioned = new Map<string, Asset[][]>()
    // Get all active filters separated into their setion
    for (const section of this.sections) {
      const activeOptions = this.getActiveOptions(section.options)
      for (const active of activeOptions) {
        if (active.assets?.length) {
          if (!sectioned.has(section.label)) {
            sectioned.set(section.label, [])
          }
          sectioned.get(section.label)?.push(active.assets)
        }
      }
    }

    return sectioned
  }

  updateProperty(key: string, prop: { key: string; value: any }): void {
    const option = this.getOption(key)
    if (option && option.props) {
      option.props[prop.key] = prop.value
    }
    // this.refresh() // Note: This probably isn't necessary, but might be for future props
  }

  setAggregations(aggregations: Aggregation[]): void {
    // Set all current aggregations to 0 (Because we don't know if they're still valid)
    for (const section of this.sections) {
      const options = section.options
      for (const key in options) {
        this.updateProperty(key, {
          key: 'number',
          value: 0
        })
        this.updateProperty(key, {
          key: 'inactive',
          value: !options[key].active
        })
      }
    }

    for (const agg in aggregations) {
      const key = aggregations[agg].bucket
      const option = this.getOption(key)
      let count = aggregations[agg].count ?? aggregations[agg].count_raw ?? 0
      // console.log('Agg', aggregations[agg], 'Option', option, 'Key', key)
      if (option) {
        if (option.active) {
          count = aggregations[agg].count ?? 0
        }
        // Set count
        this.updateProperty(key, {
          key: 'number',
          value: count
        })

        this.updateProperty(key, {
          key: 'inactive',
          value:
            !option.active &&
            (!aggregations[agg].count || aggregations[agg].count === 0)
        })

        // Increase parent count, if there is one
        const parent = this.getParentOption(option.key)
        if (parent) {
          this.updateProperty(parent.key, {
            key: 'number',
            value: (parent.props?.number ?? 0) + count
          })
        }
      }
    }
  }

  // check if a filter option is active, to be able to show/hide the reset button
  isActive(filterOptions: any): any {
    for (const key in filterOptions) {
      const option = this.getOption(key)
      if (!option) continue
      if (option.children) {
        for (const childKey in option.children) {
          const child = this.getOption(childKey)
          if (!child) continue
          if (child.active) {
            return true
          }
        }
      }
      if (option.type === FilterType.Range) {
        if (option.filter.value && option.filter.value.min > 0) {
          return true
        }
      }
      if (option.active) return true
    }
  }

  reset(section: any): void {
    const options = section.options
    for (const key in options) {
      const option = this.getOption(key)
      if (!option) continue
      if (option.children) {
        for (const childKey in option.children) {
          const child = this.getOption(childKey)
          if (!child) continue
          child.active = false
        }
      }
      if (option.type === FilterType.Range) {
        option.filter.value = 0
        option.active = 0
      } else {
        option.active = false
      }
    }
    this.refresh()
  }

  resetAll(): void {
    for (const section of this.sections) {
      this.reset(section)
    }
  }
}
