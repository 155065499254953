<script setup lang="ts">
import { useLocationStore } from '@/store/location'
import { storeToRefs } from 'pinia'
import { ref, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useSidebarStore } from '@/store/sidebars'
import { useAccountStore } from '@/store/account'
import { useFuelStore } from '@/store/fuel'
import { useMapsStore } from '@/store/maps'

import TtContainer from '@/components/TtContainer.vue'
import LocationDetails from '@/components/Sidebar/LocationDetails.vue'
import TtAccordion from '@/components/TtAccordion.vue'
import ParkingSection from '@/components/ParkingSection.vue'
import Reviews from '@/components/Reviews.vue'
import TtButton from '@/components/TtButton.vue'
import LogoFullDark from '@/components/Logo/LogoFullDark.vue'
import LogoFullLight from '@/components/Logo/LogoFullLight.vue'
import Toggle from '@/components/Icon/Toggle.vue'
import TtMetaTile from '@/components/TtMetaTile.vue'
import TtSiteOwner from '@/components/TtSiteOwner.vue'
import SiteOwnerParkingBookings from '@/components/SiteOwnerParkingBookings.vue'

const { map } = storeToRefs(useMapsStore())
const { toggleSidebar, openSidebar } = useSidebarStore()
const { open } = storeToRefs(useSidebarStore())
const { prices, averages } = storeToRefs(useFuelStore())
const { user, hasRole } = useAccountStore()

const route = useRoute()

if (!open.value) {
  openSidebar()
}

const secondaryPanelScrollElement = ref<undefined | HTMLElement>(undefined)

watch(open, () => {
  if (!open.value) {
    secondaryPanelScrollElement.value?.scrollTo(0, 0)
  }
})

const { t } = useI18n({ useScope: 'global' })

const locations = useLocationStore()

const { location } = storeToRefs(locations)

const metaOrder = {
  toilet: 1,
  'toilet-separate': 2,
  'toilet-pay': 3,
  'toilet-code': 4,
  shower: 5,
  wifi: 6,
  el: 7,
  vatten: 8,
  soptunna: 9
} as any

const filteredMetaArray = (type: string) => {
  return location.value
    ? location.value.meta
        .filter((item: any) => item.type === type)
        .filter((m: any) => m.value.key !== 'toilet-code')
        .sort((a: any, b: any) => {
          return (metaOrder[a.value.key] || 99) - (metaOrder[b.value.key] || 99)
        })
    : []
}

const fuelArray = computed(() => {
  const fuelMeta = filteredMeta('fuel')
  if (!fuelMeta) return []
  const fuelPrices = prices.value.filter(
    (item: any) => item.dataset === location.value?.dataset
  )
  fuelMeta.forEach((item: any) => {
    const fuelPrice = fuelPrices.find((i: any) => i.type === item.key)
    if (fuelPrice) {
      item.price = Number(fuelPrice.price).toFixed(2)
      const avg = averages.value.find((i: any) => i.type === item.key)
      if (avg) {
        item.average = Number(avg.avg).toFixed(2)
      }
    }
  })
  return fuelMeta
})

interface MetaItem {
  type: string
  value: { key: string; assets?: any }
  assets?: any
}

interface OpenHoursData {
  [day: string]: {
    allday?: boolean
    opens?: string
    closes?: string
  }
}

interface DayOpenHours {
  day: string
  key: string
  allday: boolean
  opens: string | null
  closes: string | null
}

const days = [
  { key: 'monday', label: t('location.features.monday') },
  { key: 'tuesday', label: t('location.features.tuesday') },
  { key: 'wednesday', label: t('location.features.wednesday') },
  { key: 'thursday', label: t('location.features.thursday') },
  { key: 'friday', label: t('location.features.friday') },
  { key: 'saturday', label: t('location.features.saturday') },
  { key: 'sunday', label: t('location.features.sunday') },
  { key: 'allweek', label: t('location.features.allweek') }
]

const getStructuredOpenHours = (meta: Record<string, any>): DayOpenHours[] => {
  return days
    .map(({ key, label }) => {
      const dayData = meta[key]
      if (!dayData) return null

      const { allday, opens, closes } = dayData

      if (allday || opens || closes) {
        return {
          day: label,
          key,
          allday: allday || false,
          opens: opens || null,
          closes: closes || null
        }
      }
      return null
    })
    .filter((day) => day !== null) as DayOpenHours[]
}

const filteredMeta = (type: string): DayOpenHours[] | any[] | undefined => {
  const filteredArray = filteredMetaArray(type) as MetaItem[] | undefined

  if (type === 'openhours' && filteredArray?.length) {
    const openHoursMeta = filteredArray.find(
      (item) => item.type === 'openhours'
    )
    if (openHoursMeta && typeof openHoursMeta.value.key === 'string') {
      const openHoursData: OpenHoursData = JSON.parse(openHoursMeta.value.key)
      return getStructuredOpenHours(openHoursData)
    }
    return []
  }

  return filteredArray?.reduce((acc, curr) => {
    const { type, value, assets } = curr
    value.assets = assets

    if (!acc[type]) {
      acc[type] = { type, value: [value] }
    } else {
      if (!acc[type].value.includes(value)) {
        acc[type].value.push(value)
      }
    }
    return acc
  }, {} as Record<string, any>)?.[type]?.value
}

const isLocationOpen = () => {
  const openHoursMeta = location.value?.meta.find(
    (item: any) => item.type === 'openhours'
  )
  const data =
    openHoursMeta && typeof openHoursMeta.value !== 'string'
      ? JSON.parse(openHoursMeta.value.key)
      : {}

  if (!openHoursMeta) {
    return
  }

  if (data.allweek && data.allweek.allday) {
    return true
  } else if (data.allweek) {
    return true
  }

  const currentDay = new Date().getDay()
  const currentTime = new Date().getHours() + ':' + new Date().getMinutes()

  const getDayData = (dayIndex: number) => data[days[dayIndex].key]

  if (getDayData(currentDay) && getDayData(currentDay).allday) {
    return true
  } else if (
    getDayData(currentDay) &&
    getDayData(currentDay).closes > currentTime
  ) {
    return true
  } else if (
    getDayData(currentDay) &&
    getDayData(currentDay).closes < currentTime
  ) {
    return false
  }
}
</script>

<template>
  <div class="pointer-events-none absolute inset-0 z-30 h-full">
    <TtContainer :open="open" variant="overlay-light">
      <div
        ref="secondaryPanelScrollElement"
        :class="`${
          open ? 'overflow-auto' : 'overflow-hidden'
        } flex h-full flex-1 flex-col rounded-t-md bg-white pb-10 sm:w-full`"
      >
        <div class="relative h-full flex-1">
          <div
            :class="`absolute top-0 z-20 w-full ${
              open
                ? 'px-5 pt-[calc(1.25rem+env(safe-area-inset-top))]'
                : 'px-3.5 pt-5'
            }`"
          >
            <div class="flex items-center justify-between gap-3">
              <router-link to="/" class="flex-shrink-0">
                <LogoFullLight v-if="location?.id" :class="`h-5 w-auto`" />
                <LogoFullDark v-else :class="`h-5 w-auto`" />
              </router-link>
              <button @click="toggleSidebar">
                <Toggle :dark="true" />
              </button>
            </div>
          </div>
          <LocationDetails>
            <template #body>
              <div class="pt-8" v-if="route.name === 'location'">
                <div
                  v-if="hasRole('siteowner') && location?.user?.id === user?.id"
                  class="mx-5 mb-5 border-b border-black/10 pb-5"
                >
                  <TtAccordion :closed="true">
                    <template v-slot:accordionButton>
                      <div class="flex items-center">
                        <h2
                          class="mr-2 text-lg font-semibold leading-9 tracking-tight"
                        >
                          {{ t('location.bookings') }}
                        </h2>
                        <TtSiteOwner />
                      </div>
                    </template>
                    <div class="mt-4" v-if="location">
                      <SiteOwnerParkingBookings
                        :location="location"
                        :hideGoTo="true"
                      />
                    </div>
                  </TtAccordion>
                </div>
                <ParkingSection />
                <div
                  v-if="filteredMetaArray('openhours').length > 0"
                  class="mx-5 mb-5 border-b border-black/10 pb-5"
                >
                  <TtAccordion :closed="true">
                    <template v-slot:accordionButton>
                      <div class="flex w-full items-center justify-between">
                        <h2
                          class="text-lg font-semibold leading-9 tracking-tight"
                        >
                          {{ t('type.openhours') }}
                        </h2>
                        <p class="flex items-center gap-1 text-tt-gray">
                          <span v-if="isLocationOpen()">
                            <svg class="h-3 w-3 text-tt-green">
                              <circle cx="5" cy="5" r="5" fill="currentColor" />
                            </svg>
                          </span>
                          <span v-else>
                            <svg class="h-3 w-3 text-tt-red">
                              <circle cx="5" cy="5" r="5" fill="currentColor" />
                            </svg>
                          </span>
                          <span>
                            {{
                              isLocationOpen()
                                ? t('openhours.open')
                                : t('openhours.closed')
                            }}
                          </span>
                        </p>
                      </div>
                    </template>
                    <div class="grid gap-1.5 pt-3">
                      <TtMetaTile
                        v-for="(item, index) in filteredMeta('openhours')"
                        :key="index"
                        :item="item"
                        :type="'openhours'"
                      />
                    </div>
                  </TtAccordion>
                </div>
                <div
                  v-if="filteredMetaArray('comfort').length > 0"
                  class="mx-5 mb-5 border-b border-black/10 pb-5"
                >
                  <TtAccordion>
                    <template v-slot:accordionButton>
                      <h2
                        class="text-lg font-semibold leading-9 tracking-tight"
                      >
                        {{ t('meta.label.comforts') }}
                      </h2>
                    </template>
                    <div class="grid gap-1.5 pt-3">
                      <TtMetaTile
                        v-for="(item, index) in filteredMeta('comfort')"
                        :key="index"
                        :item="item"
                        :type="'meta'"
                      />
                    </div>
                  </TtAccordion>
                </div>
                <div
                  v-if="fuelArray.length > 0"
                  class="mx-5 mb-5 border-b border-black/10 pb-5"
                >
                  <TtAccordion>
                    <template v-slot:accordionButton>
                      <h2
                        class="text-lg font-semibold leading-9 tracking-tight"
                      >
                        {{ t('meta.label.fuel') }}
                      </h2>
                    </template>
                    <div class="grid gap-1.5 pt-3">
                      <TtMetaTile
                        v-for="(item, index) in fuelArray"
                        :key="index"
                        :item="item"
                        :type="'fuel'"
                      />
                    </div>
                  </TtAccordion>
                </div>
                <div
                  v-if="filteredMetaArray('service').length > 0"
                  class="mx-5 mb-5 border-b border-black/10 pb-5"
                >
                  <TtAccordion>
                    <template v-slot:accordionButton>
                      <h2
                        class="text-lg font-semibold leading-9 tracking-tight"
                      >
                        {{ t('meta.label.services') }}
                      </h2>
                    </template>
                    <div class="grid gap-1.5 pt-3">
                      <TtMetaTile
                        v-for="(item, index) in filteredMeta('service')"
                        :key="index"
                        :item="item"
                        :type="'meta'"
                      />
                    </div>
                  </TtAccordion>
                </div>
                <Reviews v-if="location?.id" />
              </div>
            </template>
          </LocationDetails>
        </div>
      </div>
    </TtContainer>
  </div>
</template>
