<script setup>
import { computed, onMounted, ref } from 'vue';
import VueTimepicker from 'vue3-timepicker'

const props = defineProps({
  modelValue: {
    type: String,
    required: true
  },
  format: {
    type: String,
    default: 'hh:mm'
  },
  max: {
    type: String,
    default: ''
  }
})

const emit = defineEmits([
  'update:modelValue',
  'focus',
  'blur',
  'input'
])

const model = computed({
  get () {
    return props.modelValue
  },
  set (value) {
    emit('input', { target: { ...picker.value?.$el.querySelector('input'), value } })
    emit('update:modelValue', value)
    return value
  }
})

const picker = ref(null)

const hourRange = computed(() => {
  if (!props.max) {
    return [...Array(12).keys()].filter((n) => n !== 0)
  }
  const date1 = new Date(0)
  date1.setHours(parseInt(props.max?.split(':')[0]))
  date1.setMinutes(parseInt(props.max?.split(':')[1]))
  const hours1 = date1.getHours()
  const arr = [...Array(hours1 > 0 ? (hours1 + 1) : 12).keys()].filter((n) => n !== 0)
  return arr
})

const minuteRange = computed(() => {
  if (!props.max) {
    return [...Array(59).keys()]
  }
  const date1 = new Date(0)
  date1.setHours(parseInt(props.max?.split(':')[0]))
  date1.setMinutes(parseInt(props.max?.split(':')[1]))
  const hours1 = date1.getHours()
  const minutes1 = date1.getMinutes()

  const date2 = new Date(0)
  date2.setHours(parseInt(model.value?.split(':')[0]))
  date2.setMinutes(parseInt(model.value?.split(':')[1]))
  const hours2 = date2.getHours()

  const arr = [...Array(hours2 >= hours1 ? minutes1 + 1 : 59).keys()]
  return arr
})

const disableHours = () => {
  if (!props.max) {
    return
  }
  const el = picker.value?.$el
  Array.from(el.querySelectorAll('.dropdown .hours [data-key]')).forEach((li) => {
    if (!hourRange.value.includes(parseInt(li.dataset.key.replace(/^0/, '')))) {
      li.setAttribute('disabled', 'true')
    } else {
      li.removeAttribute('disabled')
    }
  })
}

onMounted(() => {
  const el = picker.value?.$el
  const input = el.querySelector('input')
  input.addEventListener('focus', (event) => {
    emit('focus', event)
  })
  input.addEventListener('blur', (event) => {
    emit('blur', event)
  })
  setTimeout(() => {
    Array.from(el.querySelectorAll('.dropdown .hours [data-key]')).forEach((li) => {
      li.addEventListener('click', () => {
        el.querySelector('.dropdown .minutes li[data-key="00"]').click()
        el.querySelector('.dropdown .minutes').scrollTo({
          top: -100,
          behavior: 'smooth',
        })
      });
    })
    Array.from(el.querySelectorAll('.dropdown .minutes [data-key]:not([data-key="00"])')).forEach((li) => {
      li.addEventListener('click', () => {
        el.querySelector('.dropdown').style.display = 'none';
      });
    })
    disableHours()
  }, 500)
})
</script>
<template>
  <VueTimepicker
    ref="picker"
    v-model="model"
    :format="format"
    placeholder=" "
    :hide-clear-button="true"
    :hour-range="hourRange"
    :minute-range="minuteRange"
  />
</template>

<style lang="postcss">
@import 'vue3-timepicker/dist/VueTimepicker.css';

.vue__time-picker {
  @apply w-full;
}

.vue__time-picker input.vue__time-picker-input {
  @apply focus:ring-transparent w-full border-0 bg-transparent;
}

.vue__time-picker .dropdown .hours {
  @apply flex flex-col;
}

.vue__time-picker .dropdown .hours li[data-key="12"] {
  @apply order-last;
}
</style>
