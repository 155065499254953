import { defineStore } from 'pinia'
import { ref } from 'vue'

interface Notification {
  timestamp: number
  id: string
  text: string
  type: 'notification' | 'error' | 'danger'
  closable: boolean
  background: boolean
  blocking: boolean
  completed: boolean
}

export const useNotificationsStore = defineStore({
  id: 'notifications',
  state: () => ({
    notifications: ref(new Map<string, Notification>())
  }),
  getters: {
    sortedNotifications(state) {
      return Array.from(state.notifications.values()).sort(
        (a, b) => b.timestamp - a.timestamp
      )
    }
  },
  actions: {
    addNotification(
      id: string,
      text: string,
      type: 'notification' | 'error' = 'notification',
      closable: boolean = false,
      background: boolean = false,
      blocking: boolean = false,
      completed: boolean = false
    ) {
      this.notifications.set(id, {
        timestamp: Date.now(),
        id,
        text,
        type,
        closable,
        background,
        blocking,
        completed
      })
    },
    removeNotification(id: string) {
      this.notifications.delete(id)
    }
  }
})
