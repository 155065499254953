<script lang="ts" setup>
import { computed, ref } from 'vue'
import {
  RadioGroup,
  RadioGroupLabel,
  RadioGroupOption,
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption
} from '@headlessui/vue'
import TtButton from './TtButton.vue'
import TtDatepicker from './TtDatepicker.vue'
import {
  startOfToday,
  differenceInDays,
  differenceInCalendarDays,
  isBefore,
  isToday,
  isSameDay,
  startOfYesterday
} from 'date-fns'
import { useI18n } from 'vue-i18n'
import { useLocationStore } from '@/store/location'
import { useProfileStore } from '@/store/profile'
import type { Location, Parking, ParkingBooking } from '@/location'
import type { User } from '@/account'
import TtInput from '@/components/TtInput.vue'
import TtContainer from './TtContainer.vue'
import IconParking from './Icon/ParkingPaid.vue'
import IconSwish from './Icon/Swish.vue'
import IconCard from './Icon/Card.vue'
import IconWallet from './Icon/Wallet.vue'
import IconClose from './Icon/Close.vue'
import TtParkingTile from './TtParkingTile.vue'
import ProfilePicture from './Icon/ProfilePicture.vue'
import ParkingBookingConfirmation from './ParkingBookingConfirmation.vue'
import { onMounted } from 'vue'
import axios from '@/axios'
import { useAccountStore } from '@/store/account'

const locations = useLocationStore()
const { currentCompany, currentVehicle } = useProfileStore()
const { user, hasRole } = useAccountStore()
const { t } = useI18n({ useScope: 'global' })

const props = defineProps<{
  location: Location
  parking: Parking
}>()

const modalOpen = ref(false)
const confirmationModalOpen = ref(false)
const fromDate = ref<Date | undefined>(undefined)
const toDate = ref<Date | undefined>(undefined)
const regNumber = ref(currentVehicle?.reg_number ?? '')

const bookings = ref<ParkingBooking[]>([])
onMounted(async () => {
  const fetchResponse = await locations.fetchParkingBookings(props.parking.id)
  if (fetchResponse.error) {
    return
  }
  bookings.value = fetchResponse
})

const booked = computed(() => {
  const today = new Date()
  return bookings.value.some((booking) => {
    if (new Date(booking.from) > today) {
      return false
    }
    if (new Date(booking.to) < today) {
      return false
    }
    return true
  })
})

const maxDate = computed(() => {
  // This is to prevent the user from booking a date range containing an already booked date
  if (fromDate.value) {
    const nextBookedDate = bookings.value.find(
      (booking) => new Date(booking.from) > fromDate.value!
    )
    if (nextBookedDate) {
      const from = new Date(nextBookedDate.from)
      from.setDate(from.getDate() + 1)
      return from
    }
  }
  return undefined
})
const paymentOption = ref('invoice')
const acceptTerms = ref(false)

const truckers = computed(() => {
  return currentCompany?.users ?? ([] as User[])
})
const selectedTrucker = ref(currentCompany?.users?.[0] ?? ({} as User))

const locationImageSrc = computed(() => {
  return `https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/${
    props.parking.coordinates.lng
  },${props.parking.coordinates.lat},18,0/600x400?access_token=${
    import.meta.env.VITE_MAPBOX_ACCESS_TOKEN
  }`
})

function setStartDate(value: Date) {
  fromDate.value = value
  if (toDate.value && isBefore(toDate.value, value)) {
    toDate.value = undefined
  }
}

async function bookParking(method: string) {
  await locations.bookParking({
    parking_id: props.parking.id,
    from: fromDate.value,
    to: toDate.value,
    reg_number: currentCompany?.id
      ? currentVehicle?.reg_number ?? ''
      : regNumber.value
  })
  sendInvoice()
  confirmationModalOpen.value = true
  modalOpen.value = false
}

const instance = axios.create({
  baseURL: import.meta.env.VITE_GATEWAY_URL + '/billing'
})

const sendInvoice = async () => {
  const response = await instance.post('/invoice', {
    ParkingId: props.parking.id,
    RegNumber: regNumber.value,
    From: fromDate.value,
    To: toDate.value,
    Mail: user?.email
  })
  console.log(response.data)
}

const datepickerFrom = ref<InstanceType<typeof TtDatepicker> | null>(null)
const datepickerTo = ref<InstanceType<typeof TtDatepicker> | null>(null)

const closeDateFrom = () => {
  datepickerFrom.value && datepickerFrom.value.close()
}
const closeDateTo = () => {
  datepickerTo.value && datepickerTo.value.close()
}

const fromDisabledDates = computed(() => {
  const dates = []
  dates.push({
    start: null,
    end: startOfYesterday()
  })
  if (bookings.value.length) {
    const fullyBooked = []
    bookings.value.sort((a, b) => {
      const aDate = new Date(a.from)
      const bDate = new Date(b.from)
      return aDate.getTime() - bDate.getTime()
    })
    for (let i = 0; i < bookings.value.length; i++) {
      const booking = bookings.value[i]
      const from = new Date(booking.from)
      const to = new Date(booking.to)
      const dayBeforeTo = new Date(to)
      dayBeforeTo.setDate(dayBeforeTo.getDate() - 1)
      fullyBooked.push({
        start: from,
        end: dayBeforeTo
      })

      const nextBookedDate = bookings.value[i + 1]
      const nextFrom = nextBookedDate ? new Date(nextBookedDate.from) : null
      if (nextFrom) {
        // If the next booking is the day after the current booking, and there is less than 24 hours between them, add to fully booked
        const daysDiff = differenceInDays(nextFrom, to)
        if (daysDiff == 0) {
          fullyBooked.push(to)
          fullyBooked.push(nextFrom)
        }
      }
    }
    dates.push(...fullyBooked)
  }

  return dates
})
const fromDateRules = ref({
  hours: (hour: number, { date }: { date: Date }) => {
    if (!date) return false
    // Messy, needs cleaning.
    // Checks if there is a check in/check out on the selected day, if so, only allow hours before/after the check in/check out
    // Also checks if the next day has a booking, and if so, only allow hours >24 hours before the check in
    const fromBooking = bookings.value.find((booking) => {
      const bookingDate = new Date(booking.from)
      return isSameDay(bookingDate, date)
    })
    const toBooking = bookings.value.find((booking) => {
      const bookingDate = new Date(booking.to)
      return isSameDay(bookingDate, date)
    })

    const nextDayBooking = bookings.value.find((booking) => {
      const bookingDate = new Date(booking.from)
      const nextDay = new Date(date)
      nextDay.setDate(nextDay.getDate() + 1)
      return isSameDay(bookingDate, nextDay)
    })

    let hourAllowed = true
    if (isToday(date)) {
      const now = new Date()
      hourAllowed = now.getHours() <= hour
      if (!hourAllowed) return false
    }

    if (nextDayBooking) {
      const nextDayBookingDate = new Date(nextDayBooking.from)
      hourAllowed = nextDayBookingDate.getHours() >= hour
      if (!hourAllowed) return false
    }

    if (fromBooking) {
      const from = new Date(fromBooking.from)
      hourAllowed = from.getHours() >= hour
    } else if (toBooking) {
      const to = new Date(toBooking.to)
      hourAllowed = to.getHours() <= hour
    }

    return hourAllowed
  },
  minutes: 0
})

const toDisabledDates = computed(() => {
  const dates = []
  dates.push({
    start: null,
    end: fromDate.value || startOfToday()
  })
  if (bookings.value.length) {
    const fullyBooked = []
    bookings.value.sort((a, b) => {
      const aDate = new Date(a.from)
      const bDate = new Date(b.from)
      return aDate.getTime() - bDate.getTime()
    })
    for (let i = 0; i < bookings.value.length; i++) {
      const booking = bookings.value[i]
      const from = new Date(booking.from)
      const to = new Date(booking.to)
      const dayAfterFrom = new Date(from)
      dayAfterFrom.setDate(dayAfterFrom.getDate() + 1)
      fullyBooked.push({
        start: dayAfterFrom,
        end: to
      })
    }
    dates.push(...fullyBooked)
  }
  if (maxDate.value) {
    dates.push({
      start: maxDate.value,
      end: null
    })
  }

  return dates
})
const toDateRules = ref({
  hours: (hour: number, { date }: { date: Date }) => {
    if (!date) return false
    // Messy, needs cleaning.
    // Checks if there is a check in/check out on the selected day, if so, only allow hours before/after the check in/check out
    // Also enforces a minimum 24 hour stay
    const fromBooking = bookings.value.find((booking) => {
      const bookingDate = new Date(booking.from)
      return isSameDay(bookingDate, date)
    })
    const toBooking = bookings.value.find((booking) => {
      const bookingDate = new Date(booking.to)
      return isSameDay(bookingDate, date)
    })

    if (fromDate.value) {
      const minDateHours = fromDate.value.getHours()
      if (fromBooking) {
        const from = new Date(fromBooking.from)
        return from.getHours() >= hour && hour >= minDateHours
      } else if (toBooking) {
        const to = new Date(toBooking.to)
        return to.getHours() <= hour && hour >= minDateHours
      }
      return hour >= minDateHours
    }

    return true
  },
  minutes: 0
})
</script>

<template>
  <div>
    <TtParkingTile
      :as="'button'"
      @click="modalOpen = true"
      :parking="parking"
      :available="!booked"
      :image="locationImageSrc"
    />
    <TtContainer
      @set-open="(val) => (modalOpen = val)"
      variant="popup-card-offset"
      :open="modalOpen"
    >
      <template #header>
        <div class="relative w-full">
          <img
            :src="locationImageSrc"
            alt=""
            class="mx-auto -mt-7 block aspect-[99/88] w-36 rounded object-cover"
          />
          <button
            class="absolute right-8 top-[60px]"
            @click="modalOpen = false"
          >
            <IconClose class="h-3.5 w-3.5 text-tt-gray" />
          </button>
          <div
            aria-hidden="true"
            class="absolute -bottom-6 left-0 z-10 block w-full px-1.5 pb-4 pt-2"
          >
            <div
              class="absolute inset-0 z-0 backdrop-blur-[1.5px] [mask-image:linear-gradient(transparent,white_36%)]"
            ></div>
            <div
              class="absolute inset-0 z-0 bg-gradient-to-b from-white from-40% to-[transparent]"
            ></div>
          </div>
        </div>
      </template>
      <div class="overflow-auto px-5 pb-8 pt-4.5">
        <div class="flex items-center justify-center gap-2">
          <IconParking class="h-6 w-6" />
          <h2 class="text-3xl font-semibold tracking-tight">
            {{ parking.name }}
          </h2>
        </div>
        <div class="text-center text-sm font-light">
          Lat {{ parking.coordinates.lat }}, Long {{ parking.coordinates.lng }}
        </div>
        <p class="mt-5 text-center font-light">
          Bokning hanteras av TruckTrust AB för LBC Frakt AB
        </p>
        <div class="mt-11 grid grid-cols-2 gap-x-4 gap-y-4">
          <div v-if="!currentCompany?.id" class="col-span-2">
            <TtInput
              v-model="regNumber"
              id="regNumber"
              type="text"
              :dark="true"
              :maxlength="10"
            >
              {{ t('profile.regNumber') }}
            </TtInput>
          </div>
          <div>
            <TtDatepicker
              ref="datepickerFrom"
              :is-disabled="false"
              :disabledDates="fromDisabledDates"
              :rules="fromDateRules"
              v-on:set-date="(value) => setStartDate(value)"
              v-on:toggle-calendars="closeDateTo"
            >
              Incheckning
            </TtDatepicker>
          </div>
          <div>
            <TtDatepicker
              ref="datepickerTo"
              :align="'right'"
              :isDisabled="fromDate === undefined"
              :disabledDates="toDisabledDates"
              :rules="toDateRules"
              v-on:set-date="(value) => (toDate = value)"
              v-on:toggle-calendars="closeDateFrom"
            >
              Utcheckning
              <span v-if="fromDate && toDate" class="font-light"
                >({{ differenceInCalendarDays(toDate, fromDate) }} dygn)</span
              >
            </TtDatepicker>
          </div>
          <div class="col-span-2" v-if="hasRole('companyadmin')">
            <label class="block text-lg font-medium">Gäst</label>
            <!-- TODO: Should use the TtListbox component but doesnt look ok -->
            <!-- <TtListbox
              class="relative mt-2"
              :dark="true"
              :options="truckers"
              optionDisplay="deeznuts"
              :defaultValue="selectedTrucker"
              @update="(val) => (selectedTrucker = val)"
            >
              <template #label>
                <div class="flex items-center">
                  <ProfilePicture
                    :userId="selectedTrucker.id"
                    class="h-6 w-6 rounded-full object-cover"
                  />
                  <span class="ml-2 text-lg"
                    >{{ selectedTrucker.firstname }}
                    {{ selectedTrucker.lastname }}</span
                  >
                </div>
              </template>
              <template #option="{ option }">
                <div class="flex items-center">
                  <ProfilePicture
                    :userId="option.id"
                    class="h-6 w-6 rounded-full object-cover"
                  />
                  <span class="ml-2 text-lg"
                    >{{ option.firstname }} {{ option.lastname }}</span
                  >
                </div>
              </template>
            </TtListbox> -->
            <Listbox v-model="selectedTrucker" as="div" class="relative mt-2">
              <ListboxButton
                class="form-input flex w-full items-center justify-between space-x-2 rounded border border-tt-light-gray px-3 py-3 text-base font-light transition-colors focus:border-tt-green focus:outline-none focus:ring-0"
              >
                <div class="flex items-center">
                  <ProfilePicture
                    :userId="selectedTrucker.id"
                    class="h-6 w-6 rounded-full object-cover"
                  />
                  <span class="ml-2 text-lg"
                    >{{ selectedTrucker.firstname }}
                    {{ selectedTrucker.lastname }}</span
                  >
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="6"
                  fill="none"
                  viewBox="0 0 11 6"
                >
                  <path
                    fill="#374650"
                    d="M10.313 1.063a.685.685 0 0 1-.202.486L5.986 5.674a.687.687 0 0 1-.972 0L.889 1.549A.687.687 0 1 1 1.86.576L5.5 4.216 9.14.575a.687.687 0 0 1 1.172.487Z"
                  />
                </svg>
              </ListboxButton>
              <ListboxOptions
                class="absolute z-10 mt-3 max-h-40 w-full overflow-auto rounded bg-white shadow-sidebar outline-none"
              >
                <div
                  v-for="(trucker, index) in truckers"
                  :key="trucker.id"
                  :class="`${
                    index < truckers.length - 1
                      ? 'border-b border-black/10'
                      : ''
                  }`"
                >
                  <ListboxOption
                    as="template"
                    v-slot="{ active }"
                    :key="trucker.id"
                    :value="trucker"
                  >
                    <button
                      :class="`flex w-full cursor-pointer items-center justify-between rounded px-3 py-3 text-left text-base ${
                        active && 'ring-1 ring-inset ring-tt-green'
                      }`"
                    >
                      <div class="flex items-center">
                        <ProfilePicture
                          :userId="trucker.id"
                          class="h-6 w-6 rounded-full object-cover"
                        />
                        <span class="ml-2 text-lg"
                          >{{ trucker.firstname }} {{ trucker.lastname }}</span
                        >
                      </div>
                      <span v-if="active">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="11"
                          height="8"
                          fill="none"
                        >
                          <path
                            fill="currentColor"
                            d="M9.101.47a.47.47 0 1 1 .941 0v3.296c0 .953-.772 1.725-1.726 1.725H1.607l1.706 1.707a.47.47 0 0 1-.665.665L.139 5.353a.469.469 0 0 1 0-.665l2.51-2.512a.47.47 0 0 1 .665.665L1.607 4.548h6.71a.784.784 0 0 0 .784-.784V.47Z"
                          />
                        </svg>
                      </span>
                    </button>
                  </ListboxOption>
                </div>
              </ListboxOptions>
            </Listbox>
          </div>
        </div>
        <div class="mt-8 space-y-0.5">
          <div class="flex items-center justify-between text-lg">
            <dt class="py-2 pr-2 font-light tracking-tight">1 dygn á 18,35€</dt>
            <dd class="font-mono tracking-tighter">18,35 €</dd>
          </div>
          <div class="flex items-center justify-between text-lg">
            <dt class="py-2 pr-2 font-light tracking-tight">
              Skatter och avgifter
            </dt>
            <dd class="font-mono tracking-tighter">4,75 €</dd>
          </div>
          <div class="flex items-center justify-between text-lg">
            <dt
              class="flex items-center gap-1 py-2 pr-2 font-light tracking-tight"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.608 0H2.37739C1.06472 0 0 1.06303 0 2.37361V13.6009C0 14.9115 1.06472 15.9745 2.37739 15.9745H13.6226C14.9353 15.9745 16 14.9115 16 13.6009V2.37361C15.9854 1.06303 14.9207 0 13.608 0Z"
                  fill="#C9C9C9"
                />
                <path
                  d="M2.20239 5.76643H5.10485H5.25071H7.2343C6.21333 5.76643 5.39656 6.58191 5.39656 7.60125V12.4504H7.526V5.76643V5.6645V3.64038H4.33184C3.15043 3.64038 2.20239 4.60147 2.20239 5.76643Z"
                  fill="#374650"
                />
                <path
                  d="M8.44482 3.64038V5.6645V5.76643V12.4504H10.5743V7.61581C10.5743 6.59647 9.74291 5.781 8.73653 5.781H10.7201H13.7684C13.783 4.60147 12.835 3.64038 11.6536 3.64038H8.44482Z"
                  fill="#374650"
                />
                <path
                  d="M3.26711 12.4649C3.85514 12.4649 4.33184 11.989 4.33184 11.4019C4.33184 10.8148 3.85514 10.3389 3.26711 10.3389C2.67908 10.3389 2.20239 10.8148 2.20239 11.4019C2.20239 11.989 2.67908 12.4649 3.26711 12.4649Z"
                  fill="#374650"
                />
                <path
                  d="M12.7183 12.4649C13.3063 12.4649 13.783 11.989 13.783 11.4019C13.783 10.8148 13.3063 10.3389 12.7183 10.3389C12.1303 10.3389 11.6536 10.8148 11.6536 11.4019C11.6536 11.989 12.1303 12.4649 12.7183 12.4649Z"
                  fill="#374650"
                />
              </svg>
              <span>Trusted Trucker rabatt</span>
            </dt>
            <dd class="font-mono tracking-tighter text-tt-red">-0,5 €</dd>
          </div>
          <div
            class="mt-4 flex items-center justify-between border-t border-black/10 pt-2 text-lg"
          >
            <dt class="font-semibold tracking-tight">
              Totalsumma (inkl. moms)
            </dt>
            <dd class="font-mono tracking-tighter">19,5 €</dd>
          </div>
        </div>
        <div class="mt-10">
          <RadioGroup v-model="paymentOption" class="grid gap-3">
            <RadioGroupLabel class="sr-only">Välj betalmetod</RadioGroupLabel>
            <RadioGroupOption
              v-slot="{ checked }"
              class="flex h-[3.25rem] cursor-pointer items-center justify-between rounded border border-black/10 pl-4 pr-3 text-lg font-semibold tracking-tighter"
              value="swish"
            >
              <div class="flex items-center gap-4">
                <span
                  class="flex h-4.5 w-4.5 items-center justify-center rounded-full border border-tt-light-gray"
                  ><span
                    class="h-3 w-3 rounded-full bg-tt-gray"
                    v-show="checked"
                  ></span
                ></span>
                <span>Swish</span>
              </div>
              <div class="flex h-6 w-6 items-center justify-center">
                <IconSwish class="h-6 w-6" />
              </div>
            </RadioGroupOption>
            <RadioGroupOption
              v-slot="{ checked }"
              class="flex h-[3.25rem] cursor-pointer items-center justify-between rounded border border-black/10 pl-4 pr-3 text-lg font-semibold tracking-tighter"
              value="card"
            >
              <div class="flex items-center gap-4">
                <span
                  class="flex h-4.5 w-4.5 items-center justify-center rounded-full border border-tt-light-gray"
                >
                  <span
                    class="h-3 w-3 rounded-full bg-tt-gray"
                    v-show="checked"
                  >
                  </span>
                </span>
                <span>Kortbetalning</span>
              </div>
              <div class="flex h-6 w-6 items-center justify-center">
                <IconCard class="w-5" />
              </div>
            </RadioGroupOption>
            <RadioGroupOption
              v-slot="{ checked }"
              class="flex h-[3.25rem] cursor-pointer items-center justify-between rounded border border-black/10 pl-4 pr-3 text-lg font-semibold tracking-tighter"
              value="invoice"
            >
              <div class="flex items-center gap-4">
                <span
                  class="flex h-4.5 w-4.5 items-center justify-center rounded-full border border-tt-light-gray"
                >
                  <span
                    class="h-3 w-3 rounded-full bg-tt-gray"
                    v-show="checked"
                  >
                  </span>
                </span>
                <span>Faktura till arbetsgivare</span>
              </div>
              <div class="flex h-6 w-6 items-center justify-center">
                <IconWallet class="w-5" />
              </div>
            </RadioGroupOption>
          </RadioGroup>
        </div>
        <div class="mt-6">
          <p class="font-light leading-[1.7] tracking-tight">
            Genom att klicka på ”Boka och betala” bekräftar du att du har läst
            och
            <router-link
              class="border-b border-current text-tt-pure-blue"
              to="/"
              >godkänner våra regler och villkor
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="inline"
              >
                <path
                  d="M6.11111 1.11111C5.80428 1.11111 5.55556 0.862378 5.55556 0.555556C5.55556 0.248733 5.80428 0 6.11111 0H9.44444C9.59178 0 9.73311 0.0585334 9.83728 0.162717C9.94144 0.266906 10 0.408211 10 0.555556V3.88889C10 4.19572 9.75128 4.44444 9.44444 4.44444C9.13761 4.44444 8.88889 4.19572 8.88889 3.88889V1.89679L3.72617 7.0595C3.50921 7.27644 3.15746 7.27644 2.94049 7.0595C2.72354 6.84256 2.72354 6.49078 2.94049 6.27383L8.10322 1.11111H6.11111ZM0 2.22222C0 1.60857 0.497461 1.11111 1.11111 1.11111H3.88889C4.19572 1.11111 4.44444 1.35984 4.44444 1.66667C4.44444 1.97349 4.19572 2.22222 3.88889 2.22222H1.11111V8.88889H7.77778V6.11111C7.77778 5.80428 8.0265 5.55556 8.33333 5.55556C8.64017 5.55556 8.88889 5.80428 8.88889 6.11111V8.88889C8.88889 9.50256 8.39145 10 7.77778 10H1.11111C0.497461 10 0 9.50256 0 8.88889V2.22222Z"
                  fill="currentColor"
                /></svg></router-link
            >.
          </p>
        </div>
        <div class="mt-2.5">
          <label class="flex items-center gap-2">
            <input
              type="checkbox"
              class="form-checkbox h-4.5 w-4.5 rounded border border-tt-light-gray bg-transparent text-tt-gray transition-colors focus:border-tt-green focus:outline-none focus:ring-0"
              v-model="acceptTerms"
            />
            <span class="whitespace-nowrap font-medium tracking-tight">
              Jag accepterar regler och villkor
            </span>
          </label>
        </div>
        <div class="mt-14 grid gap-1.5">
          <TtButton
            @click="bookParking('card')"
            size="large"
            type="primary"
            as="button"
            :disabled="!acceptTerms || !fromDate || !toDate"
          >
            DEMO: Boka och fakturera
          </TtButton>
          <TtButton
            @click=";(modalOpen = false), (confirmationModalOpen = true)"
            size="large"
            type="tertiary"
            as="button"
          >
            DEMO: Gå till bekräftelse
          </TtButton>
        </div>
      </div>
    </TtContainer>
    <ParkingBookingConfirmation
      :location="location"
      :parking="parking"
      :open="confirmationModalOpen"
      @close="confirmationModalOpen = false"
    />
  </div>
</template>
