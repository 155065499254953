import type {
  Vehicle,
  Company,
  VehicleType,
  BreakTime,
  SleepTime,
  FuelType
} from '@/profile.d'
import type { ParkingBooking } from '@/account.d'

import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import { useMapsStore } from '@/store/maps'
import axios from '@/axios'

const instance = axios.create({
  baseURL: import.meta.env.VITE_GATEWAY_URL
})

const jsonSerializer = {
  read: (v: string) => (v ? JSON.parse(v) : null),
  write: (v: unknown) => JSON.stringify(v)
}

export const useProfileStore = defineStore({
  id: 'profile',
  state: () => ({
    // companies: useStorage('profile.companies', [], undefined, {
    //   serializer: jsonSerializer
    // }),
    parkingBooking: useStorage<ParkingBooking>('profile.parkingBooking', null, localStorage, { serializer: jsonSerializer }),
    uploadCount: useStorage<number>('profile.uploadCount', 0),
    currentCompany: useStorage<Company | null>('profile.company', null, localStorage, { serializer: jsonSerializer }),
    currentVehicle: useStorage<Vehicle | null>('profile.vehicle', null, localStorage, { serializer: jsonSerializer }),
    currentType: useStorage<VehicleType | null>('profile.type', { id: 1, name: 'Dragbil + semi-trailer' }, localStorage, { serializer: jsonSerializer }),
    totalWeight: useStorage<Number | undefined>('profile.totalWeight', 3.5),
    totalHeight: useStorage<Number | undefined>('profile.totalHeight', 4.3),
    totalWidth: useStorage<Number | undefined>('profile.totalWidth', 2.6),
    totalLength: useStorage<Number | undefined>('profile.totalLength', 15),
    fuelType: useStorage<FuelType | null>('profile.fuelType', null, localStorage, { serializer: jsonSerializer }),
    averageConsumption: useStorage<Number | undefined>('profile.averageConsumption', 3.3),
    breakTimes: useStorage<BreakTime[]>(
      'profile.breakTimes',
      [
        { id: 1, name: '30 m.' },
        { id: 2, name: '45 m.' },
        { id: 3, name: '60 m.' }
      ],
      undefined,
      {
        serializer: jsonSerializer
      }
    ),
    currentBreakTime: useStorage<BreakTime>(
      'profile.breakTime',
      null,
      undefined,
      {
        serializer: jsonSerializer
      }
    ),
    sleepTimes: useStorage<SleepTime[]>(
      'profile.sleepTimes',
      [
        { id: 1, name: '8 h.' },
        { id: 2, name: '9 h.' },
        { id: 3, name: '10 h.' },
        { id: 3, name: '11 h.' }
      ],
      undefined,
      {
        serializer: jsonSerializer
      }
    ),
    currentSleepTime: useStorage<SleepTime>(
      'profile.sleepTime',
      null,
      undefined,
      {
        serializer: jsonSerializer
      }
    ),
    totalDrivingTime: useStorage<String | undefined>('profile.totalDrivingTime', '08:00'),
  }),
  getters: {
    getVehicleType(): VehicleType | null {
      if (this.currentVehicle?.vehicleType) {
        return this.currentVehicle.vehicleType
      }
      return this.currentType
    },
    getTotalWeight(): Number | undefined {
      if (this.currentVehicle?.totalWeight) {
        return this.currentVehicle.totalWeight
      }
      return this.totalWeight
    },
    getTotalHeight(): Number | undefined {
      if (this.currentVehicle?.totalHeight) {
        return this.currentVehicle.totalHeight
      }
      return this.totalHeight
    },
    getTotalWidth(): Number | undefined {
      if (this.currentVehicle?.totalWidth) {
        return this.currentVehicle.totalWidth
      }
      return this.totalWidth
    },
    getTotalLength(): Number | undefined {
      if (this.currentVehicle?.totalLength) {
        return this.currentVehicle.totalLength
      }
      return this.totalLength
    },
    getFuelType(): FuelType | null {
      if (this.currentVehicle?.fuelType) {
        return this.currentVehicle.fuelType
      }
      return this.fuelType
    },
    getAverageConsumption(): Number | undefined {
      if (this.currentVehicle?.averageConsumption) {
        return this.currentVehicle.averageConsumption
      }
      return this.averageConsumption
    }
  },
  actions: {
    async getCompanies(userid: string | number): Promise<Company[]> {
      const companies: Company[] = []
      try {
        const response = await instance.get('/user/' + userid + '/companies')
        if (response.data) {
          companies.push(...response.data)
        }
      } catch (error) {
        console.error(error)
      }

      const currentVehicle = this.currentCompany?.vehicles.find(
        (vehicle) => vehicle.id === this.currentVehicle?.id
      )
      if (currentVehicle === undefined) {
        this.currentVehicle = this.currentCompany?.vehicles[0] ?? null
      }

      return companies
    },

    async uploadImage(file: File, id: string|number): Promise<string> {
      const formData = new FormData()
      formData.append('file', file)
      const response = await instance.post(`/user/${id}/profile-picture`, formData)
      this.uploadCount++
      const map = useMapsStore().map
      map?.updateLocationMarkerImage()
      return response.data
    }
  }
})
