<script setup lang="ts">
import { defineProps, computed } from 'vue'
import { useAccountStore } from '@/store/account'
import { useProfileStore } from '@/store/profile'
import { storeToRefs } from 'pinia'

const account = useAccountStore()
const profile = useProfileStore()
const { user } = storeToRefs(account)
const { uploadCount } = storeToRefs(profile)

const props = defineProps({
  userId: {
    type: Number
  },
  size: {
    type: String,
    default: '32x32'
  },
  class: {
    type: String,
    default: 'h-15 block w-10 flex-shrink-0 flex-grow-0 rounded-full'
  }
})
const src = computed(() => {
  return `${import.meta.env.VITE_IMAGEPROXY_URL}/${props.size}/${
    import.meta.env.VITE_MINIO_HOST
  }/public/${props.userId ?? user.value?.id}${
    !props.userId ? '?v=' + uploadCount.value : ''
  }`
})
</script>
<template>
  <img
    :src="src"
    :class="class"
    onerror="this.src='/avatar_placeholder_TT.svg'"
  />
</template>
