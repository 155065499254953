<script setup lang="ts">
import { useSidebarStore } from '@/store/sidebars'
import SidebarSection from '@/components/Sidebar/SidebarSection.vue'
import TtAccordion from '@/components/TtAccordion.vue'
import Icon from '@/components/Icon.vue'
import TtButton from '@/components/TtButton.vue'
import { useMetaFilterStore } from '@/store/metafilter'
import { useProfileStore } from '@/store/profile'
import { useMapsStore } from '@/store/maps'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

const sidebarStore = useSidebarStore()
const filterStore = useMetaFilterStore()
const maps = useMapsStore()
const { filter, total, activeIcons } = storeToRefs(filterStore)
const { uploadCount } = storeToRefs(useProfileStore())
import ProfilePicture from '@/components/Icon/ProfilePicture.vue'

const { map } = storeToRefs(maps)

const { t } = useI18n({ useScope: 'global' })
const addNewLocationInCenter = () => {
  map.value?.pinMarker?.toggle(map.value?.getCenterOfMapView())
  // flyto center of coordinates
  map.value?.flyTo({
    center: map.value?.getCenterOfMapView(),
    zoom: 14,
    essential: true
  })
}
</script>

<template>
  <div class="px-0 pb-20 sm:pb-0">
    <SidebarSection
      v-if="filter"
      v-for="(section, i) in filter.get()"
      :key="section.label"
      :hasBorder="true"
    >
      <TtAccordion
        :icons="activeIcons.get(section.label)"
        :closed="section.label !== 'Platser'"
        :allways-show-icons="true"
      >
        <template v-slot:accordionButton>
          <h2 class="text-lg font-semibold leading-9 tracking-tight">
            {{ section.label }}
          </h2>
        </template>
        <template v-slot:accordionAction>
          <button
            v-if="filter.isActive(section.options)"
            class="text-tt-green"
            @click="filter.reset(section)"
          >
            {{ t('filter.clear') }}
          </button>
        </template>
        <div>
          <div>
            <component :is="'div'">
              <div class="space-y-1">
                <div v-for="(option, key) in section.options">
                  <component
                    :is="option.component"
                    :key="option.label"
                    v-bind="option.props"
                    @change="filter.handleEvent(key as string, $event)"
                    v-model="option.active"
                  >
                    {{ option.label }}
                    <template v-if="option?.icon" #icon>
                      <Icon :assets="option.icon" :priority="['icon-map']" />
                    </template>
                    <template v-else-if="option.key === 'my_locations'" #icon>
                      <ProfilePicture
                        :key="uploadCount"
                        size="48x48"
                        class="h-6 w-6 flex-shrink-0 flex-grow-0 rounded-full object-cover"
                      />
                    </template>
                    <template v-if="option?.children" #items>
                      <div class="mt-1 space-y-1">
                        <component
                          v-if="option.children"
                          v-for="(child, childkey) in option.children"
                          :is="child.component"
                          :key="child.label"
                          v-bind="child.props"
                          @change="
                            filter.handleEvent(childkey as string, $event)
                          "
                          v-model="child.active"
                        >
                          {{ child.label }}
                          <template v-if="child?.icon" #icon>
                            <Icon
                              :assets="child.icon"
                              :priority="['icon-map']"
                            />
                          </template>
                        </component>
                      </div>
                    </template>
                  </component>
                </div>
              </div>
            </component>
          </div>
          <div v-if="section.label === t('filter.label.places')" class="mt-10">
            <TtButton
              :as="'button'"
              :size="'large'"
              :type="'quaternary'"
              @click="addNewLocationInCenter"
            >
              {{ t('location.addCustomLocation') }}
            </TtButton>
          </div>
        </div>
      </TtAccordion>
    </SidebarSection>
    <div
      class="absolute bottom-0 z-10 block w-full px-1.5 pb-1.5 pt-8 sm:hidden"
    >
      <div
        class="absolute inset-0 z-0 [mask-image:linear-gradient(to_bottom,transparent,white_36%)]"
      >
        <div class="absolute inset-0 backdrop-blur-[1.5px]"></div>
        <div class="absolute inset-0 bg-[#35424B]"></div>
      </div>
      <div class="relative pb-safe-bottom">
        <TtButton
          :as="'button'"
          :size="'large'"
          :type="'primary'"
          class="justify-between px-3"
          @click="sidebarStore.toggleSidebar()"
        >
          <span>{{ t('filter.showResultsOnMap') }}</span>
          <div class="relative flex items-center justify-center font-medium">
            <span
              class="-my-1 h-6 min-w-6 rounded-sm border border-tt-gray px-2 py-0.5 text-base transition-colors"
            >
              {{ total }}
            </span>
          </div>
        </TtButton>
      </div>
    </div>
  </div>
</template>
