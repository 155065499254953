<template>
  <svg
    viewBox="0 0 512 437.278335"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g fill="currentColor" fill-rule="nonzero">
        <path
          d="M225.8,425.378335 L223.3,423.078335 L48.1,260.378335 C17.4,231.878335 0,191.878335 0,149.978335 L0,146.678335 C0,76.2783347 50,15.8783347 119.2,2.67833469 C158.6,-4.92166531 198.9,4.17833469 231,26.7783347 C240,33.1783347 248.4,40.5783347 256,49.0783347 C260.2,44.2783347 264.7,39.8783347 269.5,35.7783347 C273.2,32.5783347 277,29.5783347 281,26.7783347 C313.1,4.17833469 353.4,-4.92166531 392.8,2.57833469 C462,15.7783347 512,76.2783347 512,146.678335 L512,149.978335 C512,191.878335 494.6,231.878335 463.9,260.378335 L288.7,423.078335 L286.2,425.378335 C278,432.978335 267.2,437.278335 256,437.278335 C244.8,437.278335 234,433.078335 225.8,425.378335 Z"
        ></path>
      </g>
    </g>
  </svg>
</template>
