import type { Place } from '@/maps'
import LocationMarkers from '@/ttmap/location-markers'
import PinMarker from '@/ttmap/pin-marker'

import MapControls from '@/ttmap/components/MapControls.vue'

import { TTControl, attachTTComponent } from './functions'
import mapboxgl from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import pinia from '@/store/store'
import { storeToRefs } from 'pinia'
import { useMapsStore } from '@/store/maps'
import { useAccountStore } from '@/store/account'
import { useLocationStore } from '@/store/location'
import type { MetaValue } from '@/location'
import { LocationSettings } from '@/native/locationsettings'
import userPosition from '@/native/geolocation'

// Function to prompt the user to enable location services
async function checkLocationSettings() {
  // console.log('Checking if location services are enabled...')
  try {
    return (await LocationSettings.checkLocationSettings()).isLocationEnabled
  } catch (error) {
    console.error('Error checking location settings:', error)
  }
}

const mapStore = useMapsStore(pinia)
const { geometries, route, directions, currentRouteAlternative, mapStyle } =
  storeToRefs(mapStore)
const { user } = storeToRefs(useAccountStore(pinia))
const { location, tempAreaFeatureCollection, parkings } = storeToRefs(
  useLocationStore(pinia)
)

export default class TTMap extends mapboxgl.Map {
  // markers: { [key: string]: mapboxgl.Marker }
  // visibleMarkers: { [key: string]: mapboxgl.Marker }
  locations: LocationMarkers
  currentLocation?: mapboxgl.Marker
  currentHeading?: mapboxgl.Marker
  pinMarker: PinMarker
  drawInstance?: MapboxDraw
  isDrawing: boolean = false
  parkingMarkers: mapboxgl.Marker[] = []

  constructor(token: string, options: mapboxgl.MapboxOptions) {
    mapboxgl.accessToken = token
    super(options)

    this.updateCurrentLocationmarker(options?.center)
    this.addMapControls()

    this.locations = new LocationMarkers(this)
    this.pinMarker = new PinMarker(this)
    this.on('load', () => {
      this.locations.populate()
      if (!this.isDrawing) {
        this.addSelectedLocationAreaFeatures()
      }
    })
    this.on('style.load', () => {
      this.renderRoute()
      this.locations.populate()
      if (!this.isDrawing) {
        this.addSelectedLocationAreaFeatures()
      }
    })
  }

  save() {
    mapStore.saveMap(this)
  }

  fitRouteToBounds(places?: Place[]) {
    places = places ?? route.value ?? []

    if (!places || places.length == 0) {
      return
    }

    if (places.length == 1) {
      this?.setCenter(places[0].center)
      this?.setZoom(15)
      return
    }

    // Fit map to bounds
    const bounds = new mapboxgl.LngLatBounds()
    places.forEach((place) => {
      bounds.extend(place.center)
    })
    this?.fitBounds(bounds, {
      padding: {
        top: 50,
        left: 450, // offset for sidebar. TODO: make dynamic
        right: 50,
        bottom: 50
      }
    })
  }

  getCenterOfMapView() {
    return this.getCenter()
  }

  async centerOnUser() {
    const position = this.currentLocation?.getLngLat()
    if (userPosition.locationUnavailable.value) {
      if (await checkLocationSettings()) {
        userPosition.updatePosition()
      }
      return
    }

    this.flyTo({
      center: position,
      zoom: 12
    })
  }

  updateCurrentLocationmarker(
    position?: mapboxgl.LngLatLike,
    heading?: number | null
  ) {
    if (!position) return
    if (!this.currentLocation) {
      this.currentLocation = this.addCurrentLocationMarker(position, heading)
    }
    if (!this.currentHeading) {
      this.currentHeading = this.addCurrentHeadingMarker(position, heading)
    } else {
      this.currentHeading.setRotation(heading ? heading : 0)
    }
    this.currentLocation?.setLngLat(position)
    this.currentHeading?.setLngLat(position)
  }

  updateLocationMarkerImage() {
    const currentLocationMarkerImage = document.getElementById(
      'current-location-marker-image'
    ) as HTMLImageElement
    currentLocationMarkerImage.src = `${
      import.meta.env.VITE_IMAGEPROXY_URL
    }/24x24/${import.meta.env.VITE_MINIO_HOST}/public/${
      user.value?.id
    }?t=${Date.now()}`
  }

  // Don't call this function directly, call 'updateCurrentLocationMarker' instead
  addCurrentLocationMarker(
    position?: mapboxgl.LngLatLike,
    heading?: number | null
  ) {
    if (!position) return

    // Create current location marker
    const currentLocationMarker = document.createElement('div')
    const currentLocationMarkerImage = document.createElement('img')
    currentLocationMarkerImage.id = 'current-location-marker-image'
    currentLocationMarker.appendChild(currentLocationMarkerImage)
    currentLocationMarkerImage.src = `${
      import.meta.env.VITE_IMAGEPROXY_URL
    }/24x24/${import.meta.env.VITE_MINIO_HOST}/public/${user.value?.id}`
    currentLocationMarkerImage.onerror = () => {
      currentLocationMarkerImage.onerror = null
      currentLocationMarkerImage.src = '/avatar_placeholder_TT.svg'
    }
    currentLocationMarkerImage.className = 'rounded-full w-6 h-6 object-cover'
    currentLocationMarker.className =
      'relative w-6 h-6 flex justify-center items-center'

    return new mapboxgl.Marker(currentLocationMarker)
      .setLngLat(position as [number, number])
      .setRotationAlignment('viewport')
      .setPopup(
        new mapboxgl.Popup({ offset: 25 }).setHTML(
          `
        <div class="p-4">
          <h3 class="font-bold text-xl text-tt-gray">Nuvarande plats</h3>
        </div>
        `
        )
      )
      .addTo(this)
  }

  addCurrentHeadingMarker(
    position?: mapboxgl.LngLatLike,
    heading?: number | null
  ) {
    if (!position) return

    // Create current location marker
    const currentHeadingMarker = document.createElement('div')
    const currentHeadingMarkerPoint = document.createElement('div')
    if (heading !== null && heading !== -1)
      currentHeadingMarker.appendChild(currentHeadingMarkerPoint)
    currentHeadingMarker.className =
      'relative w-20 h-20 -top-7 -left-7 rounded-full border border-tt-gray/30 pointer-events-none'
    // currentHeadingMarker.style.transform = `rotate(${heading ? heading : 0}deg)`
    currentHeadingMarkerPoint.className =
      'absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full w-2 h-2 bg-tt-gray'

    return new mapboxgl.Marker(currentHeadingMarker)
      .setLngLat(position as [number, number])
      .setRotationAlignment('map')
      .setRotation(heading ? heading : 0)
      .addTo(this)
  }

  addMapControls() {
    this.addControl(new TTControl(), 'bottom-right')
    attachTTComponent(MapControls, 'tt-map-controls', { map: this })
  }

  renderRoute() {
    for (let i = 0; i < 3; i++) {
      if (this.getSource(`route-alternative`)) {
        this.removeLayer(`route-alternative`)
        this.removeLayer(`route-alternative-border`)
        this.removeSource(`route-alternative`)
      }
      if (this.getSource(`route-current`)) {
        this.removeLayer(`route-current`)
        this.removeLayer(`route-current-border`)
        this.removeSource(`route-current`)
      }
    }
    mapStore.resetMarkers()

    if (!geometries.value.length) return
    if (currentRouteAlternative.value + 1 > geometries.value.length) {
      currentRouteAlternative.value = 0
    }

    const colors = {
      'satellite-streets-v12': {
        current: '#ffffff',
        alt: '#b5b5b5'
      },
      'streets-v11': {
        current: '#374650',
        alt: '#7e8aa3'
      }
    }

    // Find a suitable layer to place the route on top of, level-crossing is a good candidate but there might be other layers that are better
    const layers = this.getStyle().layers
    let layerId
    for (const layer of layers) {
      if (layer.id === 'level-crossing') {
        layerId = layer.id
        break
      }
    }

    const currentGeometry = geometries.value[currentRouteAlternative.value]
    const alternativeGeometryIndex = geometries.value.findIndex(
      (_, i) => i !== currentRouteAlternative.value
    )
    const alternativeGeometry = geometries.value[alternativeGeometryIndex]

    const currentDirection = directions.value?.[currentRouteAlternative.value]

    this.addSource(`route-alternative`, {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: alternativeGeometry
      } as any
    })
    this.addSource(`route-current`, {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: currentGeometry
      } as any
    })
    this.addLayer(
      {
        id: `route-alternative-border`,
        type: 'line',
        source: `route-alternative`,
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': colors[mapStyle.value].alt,
          'line-opacity': 0,
          'line-width': 28
        }
      },
      layerId
    )
    this.addLayer(
      {
        id: `route-alternative`,
        type: 'line',
        source: `route-alternative`,
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': colors[mapStyle.value].alt,
          'line-width': 4
        }
      },
      layerId
    )
    this.addLayer(
      {
        id: `route-current-border`,
        type: 'line',
        source: `route-current`,
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': colors[mapStyle.value].current,
          'line-opacity': 0,
          'line-width': 28
        }
      },
      layerId
    )
    this.addLayer(
      {
        id: `route-current`,
        type: 'line',
        source: `route-current`,
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': colors[mapStyle.value].current,
          'line-width': 4
        }
      },
      layerId
    )

    this.on('click', (e) => {
      const features = this.queryRenderedFeatures(e.point, {
        layers: ['route-alternative-border', 'route-current-border']
      })
      const clickedFeature = features[0]

      if (
        clickedFeature &&
        clickedFeature.layer.id === 'route-alternative-border'
      ) {
        currentRouteAlternative.value = alternativeGeometryIndex
      }
    })

    if (!route.value) return
    route.value.forEach((point: any, i: number) => {
      const pointContainer = document.createElement('div')
      pointContainer.className = 'p-2'
      const pointMarker = document.createElement('div')
      pointMarker.className =
        'bg-white rounded-full border-[3px] ' + (point.restStop ? 'w-5 h-5 border-tt-green' : 'w-4 h-4 border-tt-gray')
      const waypoint = currentDirection.waypoints[i]
      // Get estimated arrival time, based on date
      const estimated_arrival = waypoint?.estimated_arrival
        ? Intl.DateTimeFormat('sv-SE', {
            hour: 'numeric',
            minute: 'numeric'
          }).format(new Date(waypoint?.estimated_arrival))
        : ''
      const distance = waypoint?.distance
        ? `(${Intl.NumberFormat('sv-SE', {
            maximumFractionDigits: 1
          }).format(waypoint.distance / 1000)} km)`
        : ''
      pointContainer.appendChild(pointMarker)

      const marker = new mapboxgl.Marker(pointContainer)
      marker
        .setLngLat(point.center)
        .setPopup(
          new mapboxgl.Popup({
            offset: 25,
            closeButton: false,
            //closeOnClick: false,
            closeOnMove: false
          }).setHTML(
            `
              <div>
                ${
                  point.center &&
                  `
                  <div class="relative h-[180px] w-[267px] overflow-hidden rounded-md">
                    <div
                      class="absolute left-0 top-0 h-full w-full bg-gradient-to-b from-transparent from-30% to-black/50 to-100%"
                    ></div>
                  <img class="location-img h-[108%] w-full bg-tt-gray object-cover" src="https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static/${
                    point.center[0]
                  },${point.center[1]},16,0/300x200?access_token=${
                    import.meta.env.VITE_MAPBOX_ACCESS_TOKEN
                  }" />
                  </div>`
                }
                <div class="px-6 py-5">
                  <h3 class="mb-1 w-[219px] overflow-hidden truncate font-sans text-xl font-semibold leading-snug tracking-tight text-tt-gray">${
                    point.place_name
                  }</h3>
                  <p class="font-sans text-base font-light leading-tight tracking-tighter text-tt-gray">${estimated_arrival} ${distance}</p>
                </div>
              </div>
              `
          )
        )
        .addTo(this as mapboxgl.Map)
      mapStore.saveMarker(marker)
    })

    this.fitRouteToBounds()
  }

  addSelectedLocationAreaFeatures() {
    if (!location.value) return
    this.removeLocationAreaFeatures()
    const tempColl = tempAreaFeatureCollection.value
    let savedColl = (
      location.value?.meta?.find((m: any) => m.type === 'area')
        ?.value as MetaValue
    )?.key as any
    if (savedColl) {
      try {
        savedColl = JSON.parse(savedColl)
      } catch (e) {
        savedColl = undefined
      }
    }
    const featureCollection = tempColl
      ? tempColl
      : savedColl
      ? savedColl
      : undefined
    if (featureCollection) {
      this.addSource('selected-location-area', {
        type: 'geojson',
        data: featureCollection
      })
      this.addLayer({
        id: 'selected-location-area-fill',
        type: 'fill',
        source: 'selected-location-area',
        paint: {
          'fill-color': '#e6fff5',
          'fill-opacity': 0.4
        }
      })
      this.addLayer({
        id: 'selected-location-area-line',
        type: 'line',
        source: 'selected-location-area',
        paint: {
          'line-color': '#00e1a5',
          'line-width': 2
        }
      })
    }
  }

  removeLocationAreaFeatures() {
    if (this.getSource('selected-location-area')) {
      this.removeLayer('selected-location-area-fill')
      this.removeLayer('selected-location-area-line')
      this.removeSource('selected-location-area')
    }
  }

  startDrawing() {
    this.isDrawing = true
    this.drawInstance = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true
      },
      defaultMode: 'simple_select'
    })
    this.addControl(this.drawInstance)
    const tempColl = tempAreaFeatureCollection.value
    let savedColl = (
      location.value?.meta?.find((m: any) => m.type === 'area')
        ?.value as MetaValue
    )?.key as any
    if (savedColl) {
      try {
        savedColl = JSON.parse(savedColl)
      } catch (e) {
        savedColl = undefined
      }
    }
    const featureCollection = tempColl
      ? tempColl
      : savedColl
      ? savedColl
      : undefined
    if (featureCollection) {
      this.removeLocationAreaFeatures()
      this.drawInstance.set(featureCollection)
    }

    this.drawInstance.changeMode('draw_polygon')
    this.on('draw.create', () => {
      if (this.drawInstance) {
        const data = this.drawInstance.getAll()
        console.log('draw.create', data)
      }
    })
    this.on('draw.update', () => {
      console.log('draw.update')
    })
    this.on('draw.delete', () => {
      console.log('draw.delete')
    })
  }

  stopDrawing() {
    this.isDrawing = false
    if (this.drawInstance) {
      this.removeControl(this.drawInstance)
      this.drawInstance = undefined
    }
    this.addSelectedLocationAreaFeatures()
  }

  toggleParkingSpots(open: boolean) {
    if (open) {
      this.parkingMarkers.forEach((marker) => marker.remove())
      this.parkingMarkers = []
      this.locations.hideAll()
      // Create draggable markers for each parking spot and add them to the map
      parkings.value?.forEach((spot) => {
        const el = document.createElement('div')
        el.classList.add('relative', 'p-2')
        const dot = document.createElement('div')
        dot.classList.add('h-2', 'w-2', 'rounded-full', 'bg-tt-gray')
        if (mapStyle.value === 'satellite-streets-v12') {
          dot.classList.add('ring-1', 'ring-white')
        }
        el.appendChild(dot)
        const iconWrapper = document.createElement('div')
        iconWrapper.classList.add(
          'absolute',
          'bottom-6',
          'left-1/2',
          'flex',
          'w-12',
          '-translate-x-1/2',
          'flex-wrap',
          '[&>*:first-child]:mx-auto'
        )
        iconWrapper.innerHTML = `
          <div class="bg-white rounded-[4px] flex items-center border border-[#2759FF]">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="24" rx="3" fill="#2759FF" />
              <path
                d="M8.61328 17V7.54541H12.3434C13.0605 7.54541 13.6714 7.68237 14.1761 7.95628C14.6809 8.22711 15.0656 8.60412 15.3303 9.08731C15.598 9.56743 15.7319 10.1214 15.7319 10.7492C15.7319 11.3771 15.5965 11.9311 15.3256 12.4112C15.0548 12.8913 14.6624 13.2652 14.1484 13.533C13.6375 13.8007 13.0189 13.9346 12.2926 13.9346H9.91513V12.3327H11.9695C12.3542 12.3327 12.6712 12.2665 12.9205 12.1342C13.1728 11.9988 13.3606 11.8126 13.4837 11.5756C13.6098 11.3355 13.6729 11.0601 13.6729 10.7492C13.6729 10.4353 13.6098 10.1614 13.4837 9.92751C13.3606 9.69053 13.1728 9.50741 12.9205 9.37815C12.6681 9.24581 12.348 9.17964 11.9602 9.17964H10.6122V17H8.61328Z"
                fill="white"
              />
            </svg>
            <span class="text-tt-gray text-sm font-semibold mx-1">${spot.name}</span>
          </div>
        `
        el.appendChild(iconWrapper)

        const marker = new mapboxgl.Marker({ draggable: true, element: el })
          .setLngLat([spot.coordinates.lng, spot.coordinates.lat])
          .addTo(this)
        marker.on('dragend', () => {
          const lngLat = marker.getLngLat()
          spot.coordinates.lat = lngLat.lat
          spot.coordinates.lng = lngLat.lng
        })
        this.parkingMarkers.push(marker)
      })
    } else {
      this.locations.showAll()
      this.parkingMarkers.forEach((marker) => marker.remove())
    }
  }
}
